import * as Sentry from '@sentry/react';
import { login, loginWithApple, logout, register, socialLogin } from 'api/auth';
import { getAuthedUserProfile, setUserProfile } from 'api/users';
import ReactGA from 'react-ga4';
import { toast } from 'react-toastify';
import { deleteLoginToken, saveLoginToken, setApiRequestToken } from 'utils/helpers';

import { updateRecipes } from './recipes';

const APP_READY = 'APP_READY';
const AUTHENTICATING = 'AUTHENTICATING';
const AUTH_FAILED = 'AUTH_FAILED';
const IS_AUTHED = 'IS_AUTHED';
const IS_VERIFIED = 'IS_VERIFIED';
const UPDATE_PROFILE_DATA = 'UPDATE_PROFILE_DATA';
const UPDATE_ONBOARDING_DATA = 'UPDATE_ONBOARDING_DATA';
const TOGGLE_SIGN_UP = 'TOGGLE_SIGN_UP';
const ONBOARDING_DATA_DONE = 'ONBOARDING_DATA_DONE';
const TOGGLE_LOGIN_MODAL = 'TOGGLE_LOGIN_MODAL';
const TOGGLE_PROFILE_MODAL = 'TOGGLE_PROFILE_MODAL';
const UPDATE_COUNTRY = 'UPDATE_COUNTRY';
const UPDATE_DEEPLINK = 'UPDATE_DEEPLINK';

export const LOGGING_OUT = 'LOGGING_OUT';

const DEFAULT_ONBOARDING_DATA = {
    goals: [],
    dietaries: [1],
    allergies: [],
    cooking_experience: null,
    isFinished: false,
    hasSeenOnboarding: false,
    ingredients: [],
    isEverythingSelected: true
};

export function appReady() {
    return {
        type: APP_READY
    };
}

function authenticating() {
    return {
        type: AUTHENTICATING
    };
}

function authFailed() {
    return {
        type: AUTH_FAILED
    };
}

export function isAuthed(data) {
    // use the "getAttributes" method of api sdk to get plain object from api response instead of class
    if (data.onboardingData) {
        return {
            type: IS_AUTHED,
            data: data.data._getAttributes ? data.data._getAttributes() : data.data,
            onboardingData: data.onboardingData
        };
    }

    return {
        type: IS_AUTHED,
        data: data.data._getAttributes ? data.data._getAttributes() : data.data
    };
}

export function isVerified(data) {
    return {
        type: IS_VERIFIED,
        data: data
    };
}

export function loggingOut() {
    return {
        type: LOGGING_OUT
    };
}

export function updateProfileData(data) {
    // use the "getAttributes" method of api sdk to get plain object from api response instead of class
    return {
        type: UPDATE_PROFILE_DATA,
        data: data._getAttributes ? data._getAttributes() : data
    };
}

export function handleRegisterViaEmail(userData, callback) {
    return async function(dispatch) {
        dispatch(authenticating());

        try {
            const { data: { token } } = await register(userData);

            // logAnalytics('yp_register_email');

            await saveLoginToken(token);
            await setApiRequestToken(token);

            const { data } = await getAuthedUserProfile();

            if (data.recipes && data.recipes.length > 0) {
                dispatch(updateRecipes(data.recipes));
            }

            const newUserData = {
                onboarding_data: JSON.stringify(DEFAULT_ONBOARDING_DATA)
            };

            await setUserProfile(data.id, newUserData);

            dispatch(isAuthed({ data: { ...data, ...newUserData } }));
            dispatch(updateOnboardingData(JSON.parse(newUserData.onboarding_data), true));
            callback();
        } catch (err) {
            let message = 'Sorry that the registration didn’t work out. Can you pick another sign up method?';

            if (err.response && err.response.status === 422) {
                message = 'This email has already been taken';
            }

            Sentry.withScope((scope) => {
                scope.setExtra('file', 'redux/modules/authentication.js');
                scope.setExtra('function', 'handleRegisterViaEmail');
                scope.setExtra('info', 'Failed user to register');
                Sentry.captureException(err);
            });

            toast.error(message, {
                position: toast.POSITION.BOTTOM_LEFT
            });

            dispatch(authFailed());
        }
    };
}

export function handleLoginViaEmail(email, password, callback) {
    return async function(dispatch, getState) {
        dispatch(authenticating());

        try {
            const { data: { token } } = await login(email, password);

            if (token) {
                await saveLoginToken(token);
                await setApiRequestToken(token);

                const { data } = await getAuthedUserProfile();

                if (data.recipes && data.recipes.length > 0) {
                    dispatch(updateRecipes(data.recipes));
                }

                // logAnalytics('yp_login_email');

                let updatedUserData = {};

                if (!data.onboarding_data) {
                    const currentOnboarding = getState().authentication.onboardingData;

                    updatedUserData = {
                        onboarding_data: JSON.stringify(currentOnboarding)
                    };
                }

                await setUserProfile(data.id, updatedUserData);

                const newData = { ...data, ...updatedUserData };

                dispatch(isAuthed({ data: newData }));
                dispatch(updateOnboardingData(JSON.parse(newData.onboarding_data), true));
                callback();
            } else {
                toast.error('Sorry, you entered an incorrect email address or password', {
                    position: toast.POSITION.BOTTOM_LEFT
                });
            }
        } catch (err) {
            let message = 'There was an error while logging in';

            if (err.response && err.response.status === 422) {
                message = 'Please confirm your email first before you get access to your account';
            } else if (err.response && err.response.status !== 500) {
                message = 'Sorry, you entered an incorrect email address or password';
            }

            Sentry.withScope((scope) => {
                scope.setExtra('file', 'redux/modules/authentication.js');
                scope.setExtra('function', 'handleLoginViaEmail');
                scope.setExtra('info', 'Failed user to sign in via email');
                Sentry.captureException(err);
            });

            toast.error(message, {
                position: toast.POSITION.BOTTOM_LEFT
            });
            dispatch(authFailed());
        }
    };
}

export function handleSocialLogin(params) {
    return async function(dispatch, getState) {
        dispatch(authenticating());

        const { accessToken, provider, socialData, callback, isSignUp } = params;

        try {
            const { data: { token } } = await socialLogin(accessToken, provider);

            await saveLoginToken(token);
            await setApiRequestToken(token);

            const { data } = await getAuthedUserProfile();

            if (data.recipes && data.recipes.length > 0) {
                dispatch(updateRecipes(data.recipes));
            }

            if (isSignUp) {
                ReactGA.event({
                    category: 'User',
                    action: 'Signup YoRipe Web',
                    label: `yp_register_${provider}`
                });
            } else {
                ReactGA.event({
                    category: 'User',
                    action: 'Login YoRipe Web',
                    label: `yp_login_${provider}`
                });
            }

            const currentOnboarding = getState().authentication.onboardingData;

            let newUserData = {
                onboarding_data: data.onboarding_data || JSON.stringify(currentOnboarding)
            };

            if ((!data.avatar || !data.name) && socialData) {
                newUserData = {
                    ...newUserData,
                    avatar: data.avatar ? data.avatar : socialData.avatar,
                    name: data.avatar ? data.name : socialData.name,
                    email: data.email ? data.email : socialData.email
                };
            }

            await setUserProfile(data.id, newUserData);

            dispatch(isAuthed({ data: { ...data, ...newUserData } }));
            dispatch(updateOnboardingData(JSON.parse(newUserData.onboarding_data), true));
            callback(data.avatar || data.name);
        } catch (err) {
            let message = 'Sorry that the registration didn’t work out. Can you pick another sign up method?';

            if (err.response && err.response.status === 422) {
                message = 'This email has already been taken';
            }

            Sentry.withScope((scope) => {
                scope.setExtra('file', 'redux/modules/authentication.js');
                scope.setExtra('function', 'handleSocialLogin');
                scope.setExtra('info', `Failed user to sign using ${provider}`);
                Sentry.captureException(err);
            });

            toast.error(message, {
                position: toast.POSITION.BOTTOM_LEFT
            });
            dispatch(authFailed());
        }
    };
}

export function handleAppleLogin(params) {
    /**
     * Due to apple's data limitation, it will only give `email` and `name`
     */

    return async function(dispatch, getState) {
        dispatch(authenticating());

        const { appleId, email, name } = params;

        try {
            const loginResponse = await loginWithApple(appleId, email);

            await saveLoginToken(loginResponse.data.token);
            setApiRequestToken(loginResponse.data.token);

            const { data } = await getAuthedUserProfile();

            if (data.recipes && data.recipes.length > 0) {
                dispatch(updateRecipes(data.recipes));
            }

            // isSignUp ? logAnalytics('yp_register_apple') : logAnalytics('yp_login_apple');

            const currentOnboarding = getState().authentication.onboardingData;

            let newUserData = {
                onboarding_data: data.onboarding_data || JSON.stringify(currentOnboarding)
            };

            if (!data.name) {
                newUserData = {
                    ...newUserData,
                    name
                };
            }

            await setUserProfile(data.id, newUserData);

            dispatch(isAuthed({ data: { ...data, ...newUserData } }));
            dispatch(updateOnboardingData(JSON.parse(newUserData.onboarding_data), true));
        } catch (error) {
            const message = 'Sorry that the registration didn’t work out. Can you pick another sign up method?';

            Sentry.withScope((scope) => {
                scope.setExtra('file', 'redux/modules/authentication.js');
                scope.setExtra('function', 'handleSocialLogin');
                scope.setExtra('info', 'Failed to login via apple');
                Sentry.captureException(error);
            });

            toast.error(message, {
                position: toast.POSITION.BOTTOM_LEFT
            });
            dispatch(authFailed());
        }
    };
}

export function handleLogout() {
    return async function(dispatch) {
        await logout();
        deleteLoginToken();
        dispatch(loggingOut());
    };
}

export function updateOnboardingData(data, isDoneOnboarding = null) {
    return {
        type: UPDATE_ONBOARDING_DATA,
        data,
        isDoneOnboarding
    };
}

export function doneOnboardingData() {
    return {
        type: ONBOARDING_DATA_DONE
    };
}

export function toggleLoginModal(login = true) {
    return {
        type: TOGGLE_LOGIN_MODAL,
        login: login
    };
}

export function toggleProfileModal() {
    return {
        type: TOGGLE_PROFILE_MODAL
    };
}

export function switchCountry(country = 'en') {
    return {
        type: UPDATE_COUNTRY,
        country: country
    };
}

export function updateDeeplinkUrl(url) {
    return {
        type: UPDATE_DEEPLINK,
        url: url
    };
}

const initialState = {
    isAppReady: false,
    isAuthenticating: false,
    isAuthed: false,
    profileData: {},
    onboardingData: DEFAULT_ONBOARDING_DATA,
    isDoneOnboarding: false,
    isSignUpVisible: false,
    showLoginModal: false,
    country: 'en',
    login: true,
    showCompleteProfile: false,
    deeplinkUrl: ''
};

export default function authentication(state = initialState, action) {
    switch (action.type) {
        case APP_READY:
            return {
                ...state,
                isAppReady: true
            };

        case AUTHENTICATING:
            return {
                ...state,
                isAuthenticating: true
            };

        case AUTH_FAILED:
            return {
                ...state,
                isAuthenticating: false
            };

        case IS_AUTHED:
            return {
                ...state,
                isAuthenticating: false,
                isAuthed: true,
                profileData: action.data,
                onboardingData: action.onboardingData || state.onboardingData,
                isSignUpVisible: false,
                isDoneOnboarding: true
            };

        case UPDATE_PROFILE_DATA:
            return {
                ...state,
                profileData: {
                    ...state.profileData,
                    ...action.data
                }
            };

        case UPDATE_ONBOARDING_DATA:
            return {
                ...state,
                onboardingData: action.data,
                isDoneOnboarding: action.isDoneOnboarding || state.isDoneOnboarding
            };

        case TOGGLE_SIGN_UP:
            return {
                ...state,
                isSignUpVisible: action.value,
                signUpToggleData: action.signUpToggleData
            };

        case ONBOARDING_DATA_DONE:
            return {
                ...state,
                isDoneOnboarding: true
            };

        case LOGGING_OUT:
            return {
                isAppReady: true,
                isAuthenticating: false,
                isAuthed: false,
                profileData: {},
                onboardingData: DEFAULT_ONBOARDING_DATA,
                isDoneOnboarding: true,
                isSignUpVisible: false
            };

        case IS_VERIFIED:
            return {
                ...state,
                profileData: action.data
            };

        case TOGGLE_LOGIN_MODAL:
            return {
                ...state,
                showLoginModal: !state.showLoginModal,
                login: action.login
            };
        case TOGGLE_PROFILE_MODAL:
            return {
                ...state,
                showCompleteProfile: !state.showCompleteProfile
            };
        case UPDATE_COUNTRY:
            return {
                ...state,
                country: action.country
            };

        case UPDATE_DEEPLINK:
            return {
                ...state,
                deeplinkUrl: action.url
            };

        default:
            return state;
    }
}
