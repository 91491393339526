const SET_SEARCH_QUERY = 'SET_SEARCH_QUERY';

export function setSearchValue(value) {
    return async function(dispatch) {
        dispatch({ type: SET_SEARCH_QUERY, query: value });
    };
}

const initialState = {
    query: ''
};

const search = (state = initialState, action) => {
    switch (action.type) {
        case SET_SEARCH_QUERY:
            return {
                ...state,
                query: action.query
            };

        default:
            return state;
    }
};

export default search;
