import { imageEndpoints, thumbnailPlaceholder } from 'config/constants';
import { startsWithHttp } from 'config/regexPatterns';

/**
 * Imported publishers and regular user publishers has different `image` structure and directory on S3.
 * Due to new functionality recipe upload, regular user can now upload their recipes directly into the app.
 * @param {Object} recipe
 * @returns {String}
 */
export function getRecipePublisherImage(recipe) {
    if (recipe.publisher?.user?.avatar) {
        return recipe.publisher.user.avatar;
    }

    if (!recipe.publisher?.image) {
        return null;
    }

    if (recipe?.is_uploaded) {
        const containsHttps = startsWithHttp.test(recipe.publisher?.image);

        return containsHttps ? recipe.publisher?.image : imageEndpoints.publishersUrl + recipe.publisher?.image;
    }

    return imageEndpoints.publishersUrl + recipe.publisher?.image;
}

/**
 * Imported recipe images and app submitted recipes has different `image` structure and directory on S3.
 * @param {Boolean} isUploaded
 * @param {String} recipeImage
 * @param {String} images
 * @param {String} thumbnail
 * @returns {String}
 */
export function getRecipeImage(isUploaded, recipeImage, images, thumbnail) {
    const multipleImage = images ? JSON.parse(images) : null;

    if (thumbnail) {
        return thumbnail;
    }

    if (!recipeImage && multipleImage?.length === 0) {
        return thumbnailPlaceholder;
    }

    if (multipleImage && multipleImage?.length > 0) {
        return multipleImage[0];
    }

    if (isUploaded) {
        const containsHttps = startsWithHttp.test(recipeImage);

        return containsHttps ? recipeImage : imageEndpoints.recipeUrl + recipeImage;
    }

    return imageEndpoints.recipeUrl + recipeImage;
}

export function getRecipeImages(isUploaded, recipeImage, images) {
    if (images) {
        const photos = JSON.parse(images);

        return photos;
    }

    if (!recipeImage) {
        return null;
    }

    if (isUploaded) {
        const containsHttps = startsWithHttp.test(recipeImage);

        return containsHttps ? [recipeImage] : [imageEndpoints.recipeUrl + recipeImage];
    }

    return [imageEndpoints.recipeUrl + recipeImage];
}
