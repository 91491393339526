import { addLivestreamComment, updateLivestreamCache } from 'api/livestream';
import { PUSHER_KEY, pusherChannel, pusherEvents } from 'config/constants';
import Pusher from 'pusher-js';
import { useEffect } from 'react';
import EventEmitter, { LIVESTREAM_EVENTS } from 'utils/EventEmitter';

export const createPusherClient = async () => {
    const pusher = new Pusher(PUSHER_KEY, {
        cluster: 'ap1',
        activityTimeout: 60000
    });

    pusher.connect();

    return pusher;
};

export const parsePusherData = (data) => {
    try {
        return JSON.parse(data);
    } catch {
        return [];
    }
};

export const useRealtimeEvents = () => {
    useEffect(() => {
        const init = async () => {
            const pusher = await createPusherClient();
            const channel = pusher.subscribe(pusherChannel.user.all);

            channel.bind(pusherEvents.LiveChatMessageCreated, (data) => {
                addLivestreamComment(data[0]);
                EventEmitter.emit(LIVESTREAM_EVENTS.LIVESTREAM_NEW_CHAT + data[0].live_streaming_id, data[0]);
            });

            channel.bind(pusherEvents.LiveStreamUpdated, (data) => {
                updateLivestreamCache(data[0]);
                EventEmitter.emit(LIVESTREAM_EVENTS.LIVESTREAM_UPDATE + data[0].id, data[0]);
                EventEmitter.emit(LIVESTREAM_EVENTS.UPDATE_LIST, data[0]);
            });

            channel.bind(pusherEvents.LiveStreamReactions, (data) => {
                EventEmitter.emit(LIVESTREAM_EVENTS.LIVESTREAM_REACTION + data[0].id, data[0]);
            });
        };

        init();
    }, []);
};
