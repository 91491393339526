const livestream = {
    en: {
        LIVE_STREAM: 'Live Stream',
        EDIT_LIVE_STREAM: 'Edit Live Stream',
        NO_LIVE_STREAM: 'No Live streams yet',
        LIVE_NOW: 'LIVE',
        REPLAY: 'REPLAY',
        LIVE_PREVIEW: 'Preview Mode',
        LIVE_UPCOMING: 'UPCOMING',
        LIVE_PAST: 'Past',
        LIVE_PAGE_TITLE: 'YoRipe LIVE',
        LIVE_PAGE_DESCRIPTION: 'Get real-time cooking tips & tricks from our homecooks!',

        LIVE_TITLE: 'Write a title for your live stream... *',
        LIVE_PROMOTION: 'What do you want to promote today?',
        LIVE_ITEM: 'item',
        LIVE_NO_REPLAY: 'There won’t be any replay available at this time',
        LIVE_SAVE_TEST_CAMERA: 'Save & Test Camera',
        LIVE_SHOP_PRODUCT: 'Shop/Product',
        LIVE_SCHEDULE_TIME: 'Schedule later',
        LIVE_ADD_SHOP_PRODUCT: 'Add Shop/Product',
        LIVE_NAME_SHOP_PRODUCT: 'Write product name here...',
        LIVE_LINK_SHOP_PRODUCT: 'Paste product link here...',
        LIVE_DISCARD: 'Discard',

        LIVE_MUTED: 'Muted',
        LIVE_CAMERA_OFF: 'Camera Off',
        LIVE_VIEWERS: 'Viewers',
        LIVE_NO_VIEWERS: 'No Viewers Yet',
        LIVE_SHARE_WITH_FRIENDS: 'Share with your friend',
        LIVE_NOW_BUTTON: 'Start live stream',
        LIVE_END: 'Are you sure that you want to end this live?',
        LIVE_END_NOW: 'End Now',
        LIVE_ENDED: 'lIVE STREAM INSIGHTS',
        LIVE_NUMBER_OF_VIEWS: 'No. of Views',
        LIVE_DURATION: 'Duration',
        LIVE_MINUTE: 'minutes',
        LIVE_BACK_FEED: 'Back to Feed',
        REPORT_LIVESTREAM_SUCCESS: 'Your report has been sent',
        REPORT_LIVESTREAM_FAILED: 'Failed to report livestream',
        REPORT_LIVESTREAM_FALSE: 'False information',
        REPORT_IRRELEVANT_CONTENT: 'Irrelevant content',
        REPORT_LIVESTREAM_PRODUCT: 'Product links and content do not match',
        REPORT_LIVESTREAM_FRAUD: 'Fraud/Inappropriate content',

        LIVE_PUBLISH_TO_FEED: 'Publish to Feed',
        LIVE_DISCARD_VIDEO: 'Discard Video',
        LIVE_DELETE_CONFIRM: 'Are you sure to delete this livestream?',
        LIVE_DISCARD_CONFIRM: 'Are you sure you want to discard this video?',
        LIVESTREAM_TITLE: 'Live stream information',
        SESSION_TITLE: 'Session information',
        LIVESTREAM_ERROR_REQUIRED: 'This field is required',
        LIVESTREAM_ERROR_LINK: 'Must be an external URL such as https://example.com',
        LIVESTREAM_FORM_BUTTON: 'Enter preview mode',
        LIVE_ADD_PRODUCT: 'Add products',
        LIVESTREAM_PRODUCTS: 'Promote products',
        LIVESTREAM_CARD_PREVIEW: 'This is the preview of your live session.',
        LIVESTREAM_UPCOMING_PREVIEW: 'This is the preview of your upcoming live session.',
        SUCCESS_SCHEDULED: 'Successfully scheduled.',
        SCHEDULED_INFO: 'You will be notified when the session starts.',
        SEE_SCHEDULE: 'See the schedule',
        GO_TO_PROFILE: 'Go to Profile',
        SCHEDULE_LIVE_STREAM: 'Schedule live stream',
        DELETE_SESSION: 'Are you sure want to delete the session?',
        DELETE_SESSION_WARNING: 'This action cannot be undone.',
        NO_SCHEDULED_SESSION: 'You don’t have any schedule yet.',
        NO_CURRENT_LIVESTREAMS: 'You don’t have any past Livestreams.',
        SCHEDULE_STREAM: 'Schedule a live session',
        EARLY_START_TITLE: 'Are you sure want to start the live stream now?',
        EARLY_START_TEXT: 'This action will override your scheduled session if you start the session earlier.',
        REMOVE_PRODUCT: 'Remove this item?',
        DEMO_CHAT: 'This is a test message. You’re still in preview mode.',
        PREVIEW_END: 'Are you sure want to quit the preview mode?',
        EMPTY_PROMO_TITLE: 'You don’t have anything to promote yet.',
        EMPTY_PROMO_MESSAGE: 'Tap button below if you want to add something to promote.',
        TITLE_CHANGE_INFO: 'Your audiences will see this title',
        EDIT_TITLE: 'Edit title',
        AUDIENCE_TOGGLE_TITLE: 'Broadcast audience',
        AUDIENCE_PUBLIC: 'Public',
        AUDIENCE_PUBLIC_INFO: 'Anyone can watch and will be notified when the Live Streaming is begin. ',
        AUDIENCE_PRIVATE: 'Practice',
        AUDIENCE_PRIVATE_INFO: 'Go live by yourself and nobody will be notified. You can invite your friends to watch your session.',
        TOUR_TITLE: 'Want to see something new?',
        TOUR_INFO: 'It will helps you to create a better live streaming session. 😎',
        TOUR_START: 'Yes, show me!',
        TOUR_CANCEL: 'Skip, I’m good',
        TOUR_MESSAGE_1: 'Now, you can edit the title, set the audience, and switch your camera in the preview mode.',
        TOUR_MESSAGE_2: 'This is a chat simulation if someone has join to your live stream session.',
        TOUR_MESSAGE_3: 'You can swipe horizontally to see all the items that you’ve been added before.',
        TOUR_MESSAGE_4: 'Place your best cooking angle inside the bright area above. Viewers are happy to see your cooking without any distraction.',
        TOUR_MESSAGE_5: 'Take your time. When the setup is done, you can press the button below to start the live stream session.',
        TOUR_MESSAGE_6: 'You can set the audience to Public and Practice. Exercise as much as you need.',
        MIC_TITLE: 'Mic',
        CAMERA_TITLE: 'Switch Camera',
        EDIT_TITLE_ICON: 'Edit Title',
        AUDIENCE: 'Audience',
        VIDEO_TITLE: 'Video',
        LIVE_STARTED: 'We’re telling everyone around that you’ve started a live video.',
        PRACTICE_STARTED: 'You’re in the practice mode now. You can invite your friends by copy the link at the share icon below.',
        CHECKING_CONNECTION: 'Checking your connection',
        YOU_ARE_LIVE: 'You’re live!',
        CLICK_SHOW_CHAT: 'Tap once again to have a normal view.',
        CLICK_HIDE_CHAT: 'Tap once to hide comments.',
        BACK_TO_HOME: 'Back to LIVE',
        ENDED_TITLE: 'The live stream has ended.',
        ENDED_INFO: 'Don’t worry, you can see the replay video of the session.',
        ENDED_BUTTON: 'Check other past live stream',
        UPCOMING_TITLE: 'The live stream hasn’t started yet.',
        UPCOMING_INFO: 'The host might still be preparing the ingredients.',
        EARLY_TITLE: 'You’re early!',
        EARLY_INFO: 'This live session will be starting on',
        SCHEDULE_TITLE: 'Schedule later',
        SCHEDULE_PLACEHOLDER: 'Set date and time',
        SET_SCHEDULE: 'Set schedule',
        SET_SCHEDULE_TEXT: 'I want to schedule the live stream',
        UNSAVED_CHANGES_TITLE: 'Are you sure you want to leave?',
        UNSAVED_CHANGES_TEXT: 'Your changes have not been saved.',
        UNSAVED_LEAVE: 'Leave',
        UNSAVED_STAY: 'Stay',
        LIVESTREAM_HIGH_QUALITY: 'Streaming on High Quality!',
        LIVESTREAM_LOW_QUALITY: 'Switched to Lower Quality due to Poor connection!',
        LIKE_SUCCESS: 'Successfully liked livestream',
        UNLIKE_SUCCESS: 'Removed your like from livestream',
        LIKE_FAIL: 'Failed to like livestream',
        SEND_COMMENT_FAIL: 'Failed to send comment',
        TOAST_SUCCESS_DELETE_REPLAY: 'Successfully deleted Replay.',
        TOAST_FAILED_DELETE_STREAM: 'Failed to delete Replay.',
        LIVESTREAM_LIVE_WITH: 'is live now with',
        HOST_CONNECTION_UNSTABLE: 'The host’s connection seems unstable. Please wait',
        OPEN_PRODUCT: 'View',
        SEND_MESSAGE: 'Send message',
        EMPTY_PROMO_LIST: "The host didn't promote any product yet.",
        DOWNLOAD_TITLE: 'Enjoying the show?',
        DOWNLOAD_TEXT: 'Don’t miss out the live stream fun! Use our app to interact with others!',
        DELETE_LIVE_REPLAY: 'Are you sure you want to delete the live stream post?',
        LIVESTREAM_UNAVAILABLE: 'Sorry, live stream is unavailable'
    },
    id: {
        LIVE_STREAM: 'Siaran Live',
        EDIT_LIVE_STREAM: 'Siaran Live',
        NO_LIVE_STREAM: 'Belum ada Siaran langsung',
        LIVE_NOW: 'LIVE',
        LIVE_UPCOMING: 'AKAN DATANG',
        LIVE_PAST: 'BERLALU',
        REPLAY: 'SIARAN ULANG',
        LIVE_DISCARD: 'Hapus',
        LIVE_PREVIEW: 'MODE PENGECEKAN',
        LIVE_PAGE_TITLE: 'YoRipe LIVE',
        LIVE_PAGE_DESCRIPTION: 'Dapatkan tips & trick memasak secara langsung dengan koki rumahan!',

        LIVE_TITLE: 'Tulis judul untuk live stream kamu... *',
        LIVE_PROMOTION: 'Apa yang ingin Anda promosikan hari ini?',
        LIVE_ITEM: 'buah',
        LIVE_NO_REPLAY: 'Pemutaran ulang tidak tersedia untuk saat ini',
        LIVE_SAVE_TEST_CAMERA: 'Simpan & Tes Kamera',
        LIVE_SHOP_PRODUCT: 'Toko/Produk',
        LIVE_SCHEDULE_TIME: 'Jadwal siaran live',
        LIVE_ADD_SHOP_PRODUCT: 'Tulis nama produk di sini...',
        LIVE_NAME_SHOP_PRODUCT: 'Tulis nama produk di sini...',
        LIVE_LINK_SHOP_PRODUCT: 'Tempelkan tautan toko/produk',

        LIVE_MUTED: 'Dibisukan',
        LIVE_CAMERA_OFF: 'Camera Mati',
        LIVE_VIEWERS: 'Penonton',
        LIVE_NO_VIEWERS: 'Belum ada penonton',
        LIVE_SHARE_WITH_FRIENDS: 'Bagikan Kepada Teman Anda',
        LIVE_NOW_BUTTON: 'Mulai live stream',
        LIVE_END: 'Anda yakin ingin mengakhiri siaran langsung ini?',
        LIVE_END_NOW: 'Akhiri Sekarang',
        LIVE_ENDED: 'RANGKUMAN lIVE STREAM',
        LIVE_NUMBER_OF_VIEWS: 'Jumlah Penonton',
        LIVE_DURATION: 'Durasi',
        LIVE_MINUTE: 'menit',
        LIVE_BACK_FEED: 'Kembali ke Galeri',
        PREVIEW_END: 'Apa kamu yakin ingin keluar dari mode pengecekan?',
        REPORT_LIVESTREAM_SUCCESS: 'Laporanmu telah dikirim',
        REPORT_LIVESTREAM_FAILED: 'Gagal melaporkan siaran langsung',
        REPORT_LIVESTREAM_FALSE: 'Informasi Palsu',
        REPORT_IRRELEVANT_CONTENT: 'Konten Tidak Relevan',
        REPORT_LIVESTREAM_PRODUCT: 'Tautan produk dan konten tidak sesuai',
        REPORT_LIVESTREAM_FRAUD: 'Penipuan/Konten Tidak Pantas',

        LIVE_PUBLISH_TO_FEED: 'Unggah ke beranda',
        LIVE_DISCARD_VIDEO: 'Hapus video',
        LIVE_DELETE_CONFIRM: 'Apakah Anda yakin ingin menghapus livestream ini?',
        LIVE_DISCARD_CONFIRM: 'Apakah Anda yakin ingin menghapus siaran ini?',
        LIVESTREAM_TITLE: 'Informasi sesi live stream',
        LIVESTREAM_ERROR_REQUIRED: 'Bagian ini diperlukan',
        SESSION_TITLE: 'Informasi sesi siaran live',
        LIVESTREAM_ERROR_LINK: 'Harus berupa URL eksternal seperti https://example.com',
        LIVESTREAM_FORM_BUTTON: 'Masuk mode pengecekan',
        LIVE_ADD_PRODUCT: 'Tambahkan produk',
        LIVESTREAM_PRODUCTS: 'Produk yang mau dipromosikan',
        LIVESTREAM_CARD_PREVIEW: 'Ini adalah tampilan saat kamu live nanti.',
        LIVESTREAM_UPCOMING_PREVIEW: 'Ini adalah tampilan saat kamu live nanti.',
        SUCCESS_SCHEDULED: 'Sesi berhasil dijadwalkan.',
        SCHEDULED_INFO: 'Kamu akan diberitahu saat sesi dimulai.',
        SEE_SCHEDULE: 'Lihat jadwal',
        GO_TO_PROFILE: 'Ke profil',
        SCHEDULE_LIVE_STREAM: 'Jadwalkan sesi live stream',
        DELETE_SESSION: 'Apa kamu yakin ingin menghapus sesi ini?',
        SCHEDULE_STREAM: 'Jadwalkan sesi live stream',
        DELETE_SESSION_WARNING: 'Tindakan ini tidak bisa dipulihkan.',
        NO_SCHEDULED_SESSION: 'Kamu belum memiliki sesi live stream yang akan datang',
        NO_CURRENT_LIVESTREAMS: 'Anda tidak memiliki Livestream sebelumnya.',
        EARLY_START_TITLE: 'Apa kamu yakin untuk memulai siaran live sekarang?',
        EARLY_START_TEXT: 'Jika kamu memulai sesi lebih awal, tindakan ini akan menggantikan jadwal yang telah ditentukan.',
        REMOVE_PRODUCT: 'Hapus barang ini?',
        DEMO_CHAT: 'Ini adalah pesan percobaan. Anda masih dalam mode pengecekan.',
        EMPTY_PROMO_TITLE: 'Anda belum memiliki apa pun untuk dipromosikan.',
        EMPTY_PROMO_MESSAGE: 'Ketuk tombol di bawah jika Anda ingin menambahkan sesuatu untuk dipromosikan.',
        EDIT_TITLE: 'Ganti judul',
        AUDIENCE_TOGGLE_TITLE: 'Pengaturan penonton',
        AUDIENCE_PUBLIC: 'Umum',
        AUDIENCE_PUBLIC_INFO: 'Siapa pun dapat menonton dan akan diberi tahu saat sesi dimulai.',
        AUDIENCE_PRIVATE: 'Latihan',
        AUDIENCE_PRIVATE_INFO: 'Buat sesi latihan sendiri. Anda dapat mengundang teman untuk menonton sesi Anda.',
        TITLE_CHANGE_INFO: 'Penonton akan melihat judul ini.',
        TOUR_TITLE: 'Ingin melihat sesuatu yang baru?',
        TOUR_INFO: 'Ini akan membantu Anda membuat sesi live stream jadi lebih baik. 😎',
        TOUR_START: 'Ya, tunjukkan saya',
        TOUR_CANCEL: 'Lewatkan dulu',
        TOUR_MESSAGE_1: 'Sekarang, Anda dapat mengubah judul, mengatur penonton, dan memutar kamera di mode pengecekan.',
        TOUR_MESSAGE_2: 'Ini adalah simulasi obrolan jika seseorang telah bergabung dengan sesi streaming langsung Anda.',
        TOUR_MESSAGE_3: 'Anda dapat menggeser ke kiri atau kanan untuk melihat produk promosi yang telah Anda tambahkan.',
        TOUR_MESSAGE_4: 'Pusatkan aktivitas memasak Anda di dalam area di atas. Penonton senang melihat masakan Anda dengan minim halangan/gangguan.',
        TOUR_MESSAGE_5: 'Anda dapat menekan tombol di bawah untuk memulai sesi streaming langsung setelah penyiapan selesai.',
        TOUR_MESSAGE_6: 'Anda dapat mengatur mode penonton ke Umum dan Latihan. Berlatihlah senyaman mungkin.',
        MIC_TITLE: 'Mikrofon',
        CAMERA_TITLE: 'Putar Kamera',
        EDIT_TITLE_ICON: 'Judul',
        AUDIENCE: 'Penonton',
        VIDEO_TITLE: 'Video',
        LIVE_STARTED: 'Kami memberi tahu semua orang di sekitar bahwa Anda telah memulai sesi live stream.',
        PRACTICE_STARTED: 'Kamu sedang dalam mode latihan. Undang temanmu dengan menyalin link pada bagikan di bawah ini.',
        YOU_ARE_LIVE: 'Kamu sedang Live!',
        CHECKING_CONNECTION: 'Memeriksa koneksi internet',
        CLICK_SHOW_CHAT: 'Ketuk sekali lagi untuk melihat obrolan.',
        CLICK_HIDE_CHAT: 'Ketuk sekali untuk menyembunyikan obrolan.',
        BACK_TO_HOME: 'Kembali ke LIVE',
        ENDED_TITLE: 'Siaran live telah berakhir.',
        ENDED_INFO: 'Jangan khawatir, kamu bisa tonton siaran ulang sesi ini nanti.',
        ENDED_BUTTON: 'Lihat siaran ulang lain',
        UPCOMING_TITLE: 'Streaming langsung belum dimulai.',
        UPCOMING_INFO: 'Tuan rumah mungkin masih menyiapkan bahan-bahannya.',
        EARLY_TITLE: 'Kamu datang lebih awal!',
        EARLY_INFO: 'Siaran live ini akan dimulai pada',
        SCHEDULE_TITLE: 'Jadwal siaran live',
        SCHEDULE_PLACEHOLDER: 'Atur tanggal dan waktu',
        SET_SCHEDULE: 'Jadwalkan',
        SET_SCHEDULE_TEXT: 'Saya mau menjadwalkan siaran live',
        UNSAVED_CHANGES_TITLE: 'Apakah kamu yakin ingin keluar?',
        UNSAVED_CHANGES_TEXT: 'Perubahan kamu belum disimpan.',
        UNSAVED_LEAVE: 'Leave',
        UNSAVED_STAY: 'Stay',
        LIVESTREAM_HIGH_QUALITY: 'Streaming dengan Kualitas Tinggi!',
        LIVESTREAM_LOW_QUALITY: 'Beralih ke Kualitas Lebih Rendah karena Koneksi Buruk!',
        LIKE_SUCCESS: 'Berhasil menyukai langsung',
        UNLIKE_SUCCESS: 'Menghapus suka Anda dari streaming langsung',
        LIKE_FAIL: 'gagal menyukai hidup',
        SEND_COMMENT_FAIL: 'gagal mengirim komentar',
        TOAST_SUCCESS_DELETE_REPLAY: 'Berhasil menghapus Replay.',
        TOAST_FAILED_DELETE_STREAM: 'Gagal menghapus Replay.',
        LIVESTREAM_LIVE_WITH: ' sedang siaran live',
        HOST_CONNECTION_UNSTABLE: 'Koneksi host tampaknya tidak stabil, mohon tunggu',
        OPEN_PRODUCT: 'Lihat',
        SEND_MESSAGE: 'Kirim pesan',
        EMPTY_PROMO_LIST: 'Host belum mempromosikan produk.',
        DOWNLOAD_TITLE: 'Suka dengan tontonannya?',
        DELETE_LIVE_REPLAY: 'Kamu yakin mau hapus kiriman siaran live ini?',
        DOWNLOAD_TEXT: 'Jangan ketinggalan momen seru di live! Yuk ngobrol bareng yang lain dengan aplikasi YoRipe!',
        LIVESTREAM_UNAVAILABLE: 'Maaf, Siaran live tidak tersedia'
    }
};

export default livestream;
