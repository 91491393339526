const challenge = {
    en: {
        CHALLENGE_STARTING_ON: 'Starting on',
        CHALLENGE_ACTIVE_TILL: 'Active till',
        CHALLENGE_COMPLETED_ON: 'Completed On',
        CHALLENGE_DAILY: 'Daily',
        CHALLENGE_PARTNERSHIP: 'In partnership with',
        CHALLENGE_CHALLENGERS: 'Challengers',
        CHALLENGE_HOW_TO_SUBMIT: 'How to Submit Challenge',
        CHALLENGE_COOK_YOUR_DISH: 'Cook Your Dish',
        CHALLENGE_POST_YOUR_DISH: 'Post Your Dish',
        CHALLENGE_GATHER_VOTES: 'Gather Votes',
        CHALLENGE_WIN: 'Win',
        CHALLENGE_REWARD: 'Reward',
        CHALLENGE_HOW_TO_JOIN: 'How To Join',
        CHALLENGE_SEE_MORE: 'See More',
        CHALLENGE_SEE_LESS: 'See Less',
        CHALLENGE_PROMOTION_CODE: 'Promotion Code',
        CHALLENGE_USE_NOW: 'Use Now',
        CHALLENGE_RECIPE_INSPIRATION: 'Recipe Inspiration',
        CHALLENGE_SUBMISSIONS: 'Submissions',
        CHALLENGE_JOIN_NOW: 'Join Now',
        CHALLENGE_INVITE_FRIENDS: 'Invite Friends',
        JOIN_CHALLENGE: 'Join Challenge',

        CHALLENGE_IN_PARTNER: 'In partnership with',

        CHALLENGE_VIEWS: 'views',
        CHALLENGE_CAROUSEL_TITLE: 'New Challenges of The Week',
        CHALLENGE_SUBMISSIONS_NEW: 'New Challenge Submissions',
        CHALLENGE_NEW: 'new submissions!',
        CHALLENGE_SUBMISSION_IN: 'New submissions in',
        CHALLENGE_COMPLETED: 'Completed',
        CHALLENGE_STARTING_IN: 'Starting in {value} days',
        CHALLENGE_ENDING_IN: 'Ending in {value} days',
        CHALLENGE_ENDING_TODAY: 'Ending Today',
        CHALLENGE_SUBMISSION_EMPTY: 'You are able to submit your dish when the challenge starts.'
    },
    id: {
        CHALLENGE_STARTING_ON: 'Mulai',
        CHALLENGE_ACTIVE_TILL: 'Berlangsung sampai',
        CHALLENGE_COMPLETED_ON: 'Selesai pada',
        CHALLENGE_DAILY: 'Harian',
        CHALLENGE_PARTNERSHIP: 'Bekerjasama dengan',
        CHALLENGE_CHALLENGERS: 'Penantang',
        CHALLENGE_HOW_TO_SUBMIT: 'Bagaimana Cara Mengunggah pada Tantangan Seru',
        CHALLENGE_COOK_YOUR_DISH: 'Masak Hidangan Anda',
        CHALLENGE_POST_YOUR_DISH: 'Unggah Hidangan Anda',
        CHALLENGE_GATHER_VOTES: 'Pengumpulan Suara',
        CHALLENGE_WIN: 'Menang',
        CHALLENGE_REWARD: 'Penghargaan',
        CHALLENGE_HOW_TO_JOIN: 'Bagaimana Cara untuk Bergabung',
        CHALLENGE_SEE_MORE: 'Lihat Lebih Banyak',
        CHALLENGE_SEE_LESS: 'Lihat Lebih Sedikit',
        CHALLENGE_PROMOTION_CODE: 'Kode Promosi',
        CHALLENGE_USE_NOW: 'Gunakan Sekarang',
        CHALLENGE_RECIPE_INSPIRATION: 'Inspirasi Resep',
        CHALLENGE_SUBMISSIONS: 'Unggahan',
        CHALLENGE_JOIN_NOW: 'Bergabung Sekarang',
        CHALLENGE_INVITE_FRIENDS: 'Undang Teman',
        JOIN_CHALLENGE: 'Ikuti Tantangan',

        CHALLENGE_IN_PARTNER: 'Bekerjasama dengan',

        CHALLENGE_VIEWS: 'tayangan',
        CHALLENGE_CAROUSEL_TITLE: 'Tantangan Terbaru Minggu Ini',
        CHALLENGE_SUBMISSIONS_NEW: 'Kiriman tantangan terbaru',
        CHALLENGE_NEW: 'kiriman baru!',
        CHALLENGE_SUBMISSION_IN: 'Kiriman ditambahkan di',
        CHALLENGE_COMPLETED: 'Tantangan selesai',
        CHALLENGE_STARTING_IN: 'Mulai {value} hari lagi',
        CHALLENGE_ENDING_IN: 'Berakhir {value} hari lagi',
        CHALLENGE_ENDING_TODAY: 'Berakhir Hari Ini',
        CHALLENGE_SUBMISSION_EMPTY: 'Kamu bisa upload hidangan saat tantangan sudah dimulai.'
    }
};

export default challenge;
