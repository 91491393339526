const signup = {
    en: {
        SIGNUP_HEADER_EMAIL: 'Fill Data',
        SIGNUP_HEADER_PHONE: 'Phone Number',
        SIGNUP_HEADER_FINISH: 'Finish',
        SIGNUP_FORM_TITLE: 'Fill the data',
        SIGNUP_FORM_DESCRIPTION: 'We’ll send you a One-Time Pin (OTP) to validate your account',
        SIGNUP_PHONE_TITLE: 'Phone Number',
        SIGNUP_PHONE_DESCRIPTION: 'We’ll send you a One-Time Pin (OTP) to validate your account',
        SIGNUP_VERIFY_TITLE: 'Verify OTP Number',
        SIGNUP_VERIFY_DESCRIPTION: 'Check your SMS and enter your 4 digits code here.',
        SIGNUP_VERIFIED: 'Account Verified!',
        SIGNUP_EMAIL: 'Email',
        SIGNUP_NAME: 'Full Name',
        SIGNUP_PASSWORD: 'Password',
        CONFIRM_PASSWORD: 'Confirm Password',
        SIGNUP_NUMBER: 'Phone Number',
        SIGNUP_OTP: 'OTP',
        SIGNUP_NEXT: 'Next',
        SIGNUP_PREV: 'Previous',
        SIGNUP_HOME: 'Go To Homepage',
        NAME: 'Name',
        USERNAME: 'Username',
        SIGNUP_LOCATION: 'Location',
        COMPLETE_PROFILE: 'Complete My Profile',
        PICTURE_TEXT: 'Please upload your profile picture',
        SIGNUP_WELCOME: 'Welcome to YoRipe',
        SIGNUP_CONGRATS: 'Congrats! You have successfully joined the YoRipe Community',
        VIEW_PROFILE: 'View My Profile',
        VIEW_LATER: 'View Later'
    },
    id: {
        SIGNUP_HEADER_EMAIL: 'Isi Data',
        SIGNUP_HEADER_PHONE: 'Nomor Handphone',
        SIGNUP_HEADER_FINISH: 'Selesai',
        SIGNUP_FORM_TITLE: 'Isi Data',
        SIGNUP_FORM_DESCRIPTION: 'Kami akan mengirimkan One-Time-Pin (OTP) untuk memvalidasi akun anda',
        SIGNUP_PHONE_TITLE: 'Nomor Handphone',
        SIGNUP_PHONE_DESCRIPTION: 'Kami akan mengirimkan One-Time-Pin (OTP) untuk memvalidasi akun anda',
        SIGNUP_VERIFY_TITLE: 'Verifikasi Nomor OTP',
        SIGNUP_VERIFY_DESCRIPTION: 'Cek SMS anda dan masukkan 4 digit kode anda disini.',
        SIGNUP_VERIFIED: 'Akun Terverifikasi!',
        SIGNUP_EMAIL: 'Email',
        SIGNUP_NAME: 'Nama Lengkap',
        SIGNUP_PASSWORD: 'Kata Sandi',
        CONFIRM_PASSWORD: 'Konfirmasi Kata Sandi',
        SIGNUP_NUMBER: 'Nomor Handphone',
        SIGNUP_OTP: 'OTP',
        SIGNUP_NEXT: 'Lanjut',
        SIGNUP_PREV: 'Kembali',
        SIGNUP_HOME: 'Pergi ke Beranda',
        NAME: 'Nama',
        USERNAME: 'Nama pengguna',
        SIGNUP_LOCATION: 'Lokasi',
        COMPLETE_PROFILE: 'Lengkapi Profil',
        PICTURE_TEXT: 'Silahkan upload foto profil kamu',
        SIGNUP_WELCOME: 'Selamat datang di YoRipe',
        SIGNUP_CONGRATS: 'Selamat! Kamu berhasil bergabung dengan komunitas YoRipe',
        VIEW_PROFILE: 'Lihat Profil Saya',
        VIEW_LATER: 'Lihat Nanti'
    }
};

export default signup;
