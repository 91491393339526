const groupChat = {
    en: {
        GROUPS: 'Groups',
        GROUPS_SUBTEXT: 'Explore our community groups to connect, share & collaborate',
        MEMBER_COUNT: '{count} Members',
        GROUP_LIST_TITLE: 'YoRipe’s Groups',
        FILTER_ALL: 'All',
        FILTER_LATEST: 'Latest',
        FILTER_MEMBER: 'Most Members',
        NO_GROUP_TITLE: 'There are no groups available right now',
        NO_GROUP_DESC: 'Keep exploring or start your own group on the app to connect with others 💕',
        DESC: 'Description',
        MEMBERS: 'Members',
        ADMIN: 'Admin',
        OWNER: 'Owner',
        DISCOVER: 'Discover more members',
        JOIN: 'Join group',
        JOIN_MODAL_TITLE: 'Wanna chat with others?',
        JOIN_MODAL_DESC: "Don't miss out on the lively chats! Use our app to interact with others.",
        DOWNLOAD: 'Download'
    },

    id: {
        GROUPS: 'Grup',
        GROUPS_SUBTEXT: 'Temukan berbagai grup komunitas untuk terhubung, berbagi & berkolaborasi',
        MEMBER_COUNT: '{count} Anggota',
        GROUP_LIST_TITLE: 'Grup di YoRipe',
        FILTER_ALL: 'Semua',
        FILTER_LATEST: 'Terbaru',
        FILTER_MEMBER: 'Anggota terbanyak',
        NO_GROUP_TITLE: 'Belum ada grup saat ini',
        NO_GROUP_DESC: 'Temukan hal seru lainnya atau bikin grupmu sendiri di YoRipe buat kenalan baru 💕',
        DESC: 'Deskipsi',
        MEMBERS: 'Anggota',
        ADMIN: 'Admin',
        OWNER: 'Pemilik',
        DISCOVER: 'Lihat lebih banyak anggota',
        JOIN: 'Bergabung dengan grup',
        JOIN_MODAL_TITLE: 'Mau ikutan ngobrol bareng?',
        JOIN_MODAL_DESC: 'Jangan ketinggalan obrolan seru di dalam grup! Yuk, ngobrol bareng yang lain dengan aplikasi YoRipe.',
        DOWNLOAD: 'Download'
    }
};

export default groupChat;
