import 'styles/styles.scss';
import 'react-toastify/dist/ReactToastify.css';

import loadable from '@loadable/component';
import { useGoogleOneTapLogin } from '@react-oauth/google';
import { googleClientIds, IMAGEKIT_ENDPOINT } from 'config/constants';
import ROUTE_PATHS from 'config/routePaths';
import Routes from 'config/routes';
import useAppPreparation from 'hooks/useAppPreparation';
import { IKContext } from 'imagekitio-react';
import React, { memo, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import { parseJwt } from 'utils/helpers';
import { handleSocialLogin, toggleProfileModal } from 'utils/redux/modules/authentication';

const LoginContainer = loadable(() => import('containers/Login/Login'));
const SignupContainer = loadable(() => import('containers/SignUp/Signup'));
const Forgotpassword = loadable(() => import('containers/ForgotPassword/Forgotpassword'));
const LoginModal = loadable(() => import('components/LoginModal'));
const CompleteProfile = loadable(() => import('components/CompleteProfile'));

const mapStateToProps = ({ authentication }) => {
    return {
        profileData: authentication.profileData,
        showLoginModal: authentication.showLoginModal,
        showCompleteProfile: authentication.showCompleteProfile
    };
};

const GoogleOneTap = () => {
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const loginCallback = useCallback((hasProfileData) => {
        if (!hasProfileData) {
            dispatch(toggleProfileModal());
        }
    }, [dispatch]);

    const fetchGoogleUser = useCallback((tokenResponse, socialData) => {
        const accessToken = tokenResponse.access_token;

        if (typeof accessToken === 'undefined' && !accessToken) {
            toast.error(t('error.ERROR_LOGIN'), {
                position: toast.POSITION.BOTTOM_LEFT
            });
        } else {
            dispatch(handleSocialLogin({ accessToken, provider: 'google', socialData, isSignUp: false, callback: loginCallback }));
        }
    }, [dispatch, loginCallback, t]);

    useGoogleOneTapLogin({
        onSuccess: res => {
            const response = parseJwt(res.credential);

            if (!response.email_verified) {
                toast.error(t('error.ERROR_LOGIN'), {
                    position: toast.POSITION.BOTTOM_LEFT
                });
            } else {
                const client = window.google.accounts.oauth2.initTokenClient({
                    client_id: googleClientIds.webClientId,
                    scope: 'https://www.googleapis.com/auth/userinfo.profile',
                    hint: response.email,
                    prompt: '', // Specified as an empty string to auto select the account which we have already consented for use.
                    callback: (tokenResponse) => fetchGoogleUser(tokenResponse, {
                        name: response?.name,
                        photo: response?.picture,
                        email: response?.email
                    })
                });

                client.requestAccessToken();
            }
        },
        onError: () => {
            toast.error(t('error.ERROR_LOGIN'), {
                position: toast.POSITION.BOTTOM_LEFT
            });
        },
        auto_select: true
    });

    return (
        <></>
    );
};

function App() {
    const isAppReady = useAppPreparation();
    const dispatch = useDispatch();
    const { showLoginModal, showCompleteProfile, profileData } = useSelector(mapStateToProps);

    useEffect(() => {
        if (profileData?.id && profileData?.name === null) {
            dispatch(toggleProfileModal());
        }
    }, [profileData?.id, profileData?.name, dispatch]);

    return (
        <div>
            {isAppReady &&
            <IKContext urlEndpoint={IMAGEKIT_ENDPOINT}><Switch>
                <Route path={ROUTE_PATHS.LOGIN} exact={true} component={LoginContainer} />
                <Route path={ROUTE_PATHS.SIGNUP} exact={true} component={SignupContainer} />
                <Route path={ROUTE_PATHS.FORGOT_PASSWORD} exact={true} component={Forgotpassword} />
                <Route component={Routes} />
            </Switch></IKContext>}
            <ToastContainer />
            {showLoginModal && <LoginModal />}
            {showCompleteProfile && <CompleteProfile />}
            {!profileData.id && <GoogleOneTap />}
        </div>
    );
}

export default memo(App);
