import { EventEmitter } from 'fbemitter';

export const SCROLL_TO_TOP_EVENTS = {
    FEED: 'FEED',
    FEED_STORIES: 'FEED_STORIES',
    FEED_RECIPES: 'FEED_RECIPES',
    FEED_CHALLENGES: 'FEED_CHALLENGES',
    COOK: 'COOK',
    SHOP: 'SHOP',
    SHOP_RECIPES: 'SHOP_RECIPES',
    SHOP_MY_LIST: 'SHOP_MY_LIST',
    SHOP_INGREDIENTS: 'SHOP_INGREDIENTS',
    PROFILE: 'PROFILE'
};

export const PROFILE_PAGE_EVENTS = {
    PROFILE_REFRESH: 'PROFILE_REFRESH'
};

export const RECIPE_EVENTS = {
    // append id after `_`
    RECIPE_UPDATE: 'RECIPE_UPDATE_',

    RECIPE_UPDATE_DATA: 'RECIPE_UPDATE_DATA',

    // pass recipe id
    RECIPE_TOGGLE_SAVE: 'RECIPE_TOGGLE_SAVE',

    // append recipe id after _
    NEW_RECIPE_COMMENT: 'NEW_RECIPE_COMMENT_',

    // append recipe id after _ and pass comment id
    // do not append review id if will be used in more general state (e.g. Feed)
    DELETE_RECIPE_COMMENT: 'DELETE_RECIPE_COMMENT_',

    // append recipe id after _ and pass comment data
    UPDATE_RECIPE_COMMENT: 'UPDATE_RECIPE_COMMENT_',
    RECIPE_DELETE: 'RECIPE_DELETE'
};

export const REVIEW_EVENTS = {
    REVIEW_UPDATE_DATA: 'REVIEW_UPDATE_DATA',

    // pass review id
    REVIEW_TOGGLE_SAVE: 'REVIEW_TOGGLE_SAVE',
    REVIEW_DELETE: 'REVIEW_DELETE',

    // append review id after _
    NEW_REVIEW_COMMENT: 'NEW_REVIEW_COMMENT_',

    // append review id after _ and pass comment id
    // do not append review id if will be used in more general state (e.g. Feed)
    DELETE_REVIEW_COMMENT: 'DELETE_REVIEW_COMMENT_',

    // append review id after _ and pass comment data
    UPDATE_REVIEW_COMMENT: 'UPDATE_REVIEW_COMMENT_'
};

export const CHALLENGE_EVENTS = {
    // append id after `_`
    CHALLENGE_UPDATE: 'CHALLENGE_UPDATE_'
};

export const HIDE_REACTIONS = 'HIDE_REACTIONS';

export const FEED_EVENTS = {
    // append id
    PLAY_RECIPE_VIDEO: 'PLAY_RECIPE_VIDEO_',
    PLAY_REVIEW_VIDEO: 'PLAY_REVIEW_VIDEO_',
    PLAY_LIVESTREAM_VIDEO: 'PLAY_LIVESTREAM_VIDEO_',
    PLAY_COOKING_STORY_VIDEO: 'PLAY_COOKING_STORY_VIDEO_',
    PLAY_QUESTION_VIDEO: 'PLAY_QUESTION_VIDEO_',
    PLAY_RECOMMENDATION_VIDEO: 'PLAY_RECOMMENDATION_VIDEO_',
    REFRESH_FEED_DATA: 'REFRESH_FEED_DATA'
};

export const LIVESTREAM_EVENTS = {
    FETCH_LIST: 'FETCH_LIST',
    UPDATE_LIST: 'UPDATE_LIST',
    LIVESTREAM_UPDATE: 'LIVESTREAM_UPDATE_',
    LIVESTREAM_REACTION: 'LIVESTREAM_REACTION_',
    LIVESTREAM_NEW_CHAT: 'LIVESTREAM_NEW_CHAT_',
    LIVESTREAM_DELETE: 'LIVESTREAM_DELETE'
};

const EventEmitterInstance = new EventEmitter();

export default EventEmitterInstance;
