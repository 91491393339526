import { googleApiKey } from 'config/constants';

const Eloquent = window.Eloquent;

/**
 * Get logged in user's profile data
 * @returns {Promise}
 */
export function getAuthedUserProfile() {
    return Eloquent.User.me();
}

/**
 * Get logged in user's profile data
 * @param {String} userId
 * @returns {Promise}
 */
export function getOneUser(userId) {
    return Eloquent.User.getOne(userId);
}

/**
 * Set logged in user's profile data
 * @param {Number} id
 * @param {Object} data
 * @returns {Promise}
 */
export function setUserProfile(id, data) {
    return Eloquent.User.updateOne(id, data);
}

/**
 * create family member
 * @param {String} email
 * @param {String} name
 * @returns {Promise}
 */
export function createFamilyMember(email, name) {
    return Eloquent.User.createFamilyMember({
        email,
        name
    });
}

/**
 * Handle reverse geocoding
 * @param {Number} latitude
 * @param {Number} longitude
 * @returns {Promise}
 */
export async function handleReverseGeocoding(latitude, longitude) {
    const latlng = `${latitude}, ${longitude}`;

    const response = await fetch('https://maps.googleapis.com/maps/api/geocode/json?address=' + latlng + '&key=' + googleApiKey);

    if (response.ok) {
        return response.status === 204 ? true : response.json();
    } else {
        // eslint-disable-next-line prefer-const
        let error = new Error(response.statusText);

        error.response = response;
        throw error;
    }
}

/**
 * Get most recent version
 * @returns {Promise}
 */
export async function getRecentVersion() {
    return Eloquent.User.getRecentVersion();
}

/**
 * Get User Reviews
 * @param {Number} userId
 * @returns {Promise}
 */
export async function getUserReviews(userId) {
    return Eloquent.User.getReviews(userId);
}

/**
 * Get User Reviews Paginated
 * @param {Number} userId
 * @param {Object} data
 * @returns {Promise}
 */
export async function getUserReviewsPaginated(userId, data) {
    return Eloquent.User.getReviewsPaginated(userId, data);
}

/**
 * Get User Saved Stories
 * @param {Number} userId
 * @returns {Promise}
 */
export async function getSavedStories(userId) {
    return Eloquent.User.getReviewSaves(userId);
}

/**
 * Get User Saved Stories Paginated
 * @param {Number} userId
 * @param {Object} data
 * @returns {Promise}
 */
export async function getSavedStoriesPaginated(userId, data) {
    return Eloquent.User.getReviewSavesPaginated(userId, data);
}

/**
 * Get User Favorites
 * @param {Number} userId
 * @returns {Promise}
 */
export async function getUserFavorites(userId) {
    return Eloquent.User.getFavorites(userId);
}

/**
 * Get User Favorites Paginated
 * @param {Number} userId
 * @param {Object} data
 * @returns {Promise}
 */
export async function getUserFavoritesPaginated(userId, data) {
    return Eloquent.User.getFavoritesPaginated(userId, data);
}

/**
 * Get User Likes
 * @param {Number} userId
 * @returns {Promise}
 */
export async function getUserLikes(userId) {
    return Eloquent.User.getLikes(userId);
}

/**
 * Get last viewed recipes of a user
 * @param {Number} userId
 * @returns {Promise}
 */
export function getLastViewed(userId) {
    return Eloquent.Recipe.getLastViewed(userId);
}

/**
 * Get User Likes Paginated
 * @param {Number} userId
 * @param {Object} data
 * @returns {Promise}
 */
export async function getUserLikesPaginated(userId, data) {
    return Eloquent.User.getLikesPaginated(userId, data);
}

/**
 * Resend Verification Code
 * @param {Number} userID
 * @returns {Promise}
 */
export async function resendVerificationCode(userID) {
    return Eloquent.User.resendVerificationCode(userID);
}

/**
 * verifyEmail
 * @param {Number} code
 * @returns {Promise}
 */
export async function verifyEmail(code) {
    return Eloquent.User.verifyEmail(code);
}

/**
 * Updates last login/open
 * @returns {Promise}
 */
export async function updateLastLogin() {
    return Eloquent.User.updateLastLogin();
}

/**
 * Get Recicpes by User
 * @param {Number} id
 * @param {Object} data
 * @returns {Promise}
 */
export async function getRecipesByUser(id, data) {
    return Eloquent.User.getRecipesByUser(id, data);
}

/**
 * Get Recicpes by Authed User
 * @param {Object} data
 * @returns {Promise}
 */
export async function getRecipes(data) {
    return Eloquent.User.getRecipes(data);
}
