import { queryClient, queryKeys } from 'api';
import { Eloquent } from 'config/constants';

/**
 * Generate Token for Livestreaming
 * @param {Object} data
 * @returns {Promise}
 */
export function generateToken(data) {
    return Eloquent.LiveStreaming.generateToken(data);
}

/**
 * Get all livestream
 * @param {Object} data
 * @returns {Promise}
 */
export function getAllLiveStreaming(data) {
    return Eloquent.LiveStreaming.getAll(data);
}

/**
 * Get all livestream
 * @param {Number} id
 * @returns {Promise}
 */
export function getOneLiveStream(id) {
    return Eloquent.LiveStreaming.getOne(id);
}

/**
 * Create livestream data
 * @param {Object} data
 * @returns {Promise}
 */
export function createOneLiveStream(data) {
    return Eloquent.LiveStreaming.createOne(data);
}

/**
 * Create livestream data
 * @param {Number} id
 * @param {Object} data
 * @returns {Promise}
 */
export function updateOneLiveStream(id, data) {
    return Eloquent.LiveStreaming.updateOne(id, data);
}

/**
 * Get all messages
 * @param {Number} id
 * @param {Object} data
 * @returns {Promise}
 */
export function getAllMessages(id, data) {
    return Eloquent.LiveChat.getMessagesByLiveStreamID(id, data);
}

/**
 * Send livestream message
 * @param {Object} data
 * @returns {Promise}
 */
export function createMessage(data) {
    return Eloquent.LiveChat.createMessage(data);
}

/**
 * Send livestream message
 * @param {Number} id
 * @returns {Promise}
 */
export function deleteMessage(id) {
    return Eloquent.LiveChat.delete(id);
}

/**
 * Send livestream message
 * @param {Number} id
 * @param {Object} data
 * @returns {Promise}
 */
export function reportMessage(id, data) {
    return Eloquent.LiveChat.report(id, data);
}

/**
 * Remove user
 * @param {Number} id
 * @param {String} token
 * @returns {Promise}
 */
export function removeUser(id, token) {
    return Eloquent.LiveStreaming.removeUsers(id, { token });
}

/**
 * Fetch users
 * @param {Number} id
 * @returns {Promise}
 */
export function getUsers(id) {
    return Eloquent.LiveStreaming.getUsers(id);
}

/**
 * Report livestream
 * @param {Number} id
 * @param {Object} data
 * @returns {Promise}
 */
export function reportLivestream(id, data) {
    return Eloquent.LiveStreaming.report(id, data);
}

/**
 * Get User Livestream
 * @param {Number} userId
 * @param {Object} data
 * @returns {Promise}
 */
export function getUserLivestream(userId, data) {
    return Eloquent.LiveStreaming.getByUser(userId, data);
}

/**
 * Increase highest views
 * @param {Number} id
 * @returns {Promise}
 */
export function increaseHighestViews(id) {
    return Eloquent.LiveStreaming.increaseHighestViews(id);
}

/**
 * Search Livestream
 * @param {Object} data
 * @returns {Promise}
 */
export function searchLivestream(data) {
    return Eloquent.LiveStreaming.search(data);
}

/**
 * Search Livestream
 * @param {Number} id
 * @returns {Promise}
 */
export function deleteLivestream(id) {
    return Eloquent.LiveStreaming.delete(id);
}

/**
 * Ping Livestream
 * @param {Number} id
 * @returns {Promise}
 */
export function updateLivestreamPing(id) {
    return Eloquent.LiveStreaming.updatePing(id);
}

/**
 * Stops the livestream of authenticated user
 * @returns {Promise}
 */
export function closeAllByUser() {
    return Eloquent.LiveStreaming.closeAllByUser();
}

/**
 * Send reaction
 * @param {Number} id
 * @returns {Promise}
 */
export function addReaction(id) {
    return Eloquent.LiveStreaming.addReaction(id);
}

/**
 * Like
 * @param {Number} id
 * @param {Number} type
 * @returns {Promise}
 */
export function likeLivestream(id, type) {
    return Eloquent.LiveStreamingLike.toggleLike({
        live_streaming_id: id,
        type: type
    });
}

/**
 * Deletes Livestram on Cache
 * @param {Number} id
 * @returns {void}
 */
export const deleteLivestreamCache = (id) => {
    queryClient.setQueriesData([queryKeys.feedPosts], (data) => {
        if (data?.pages) {
            return {
                ...data,
                pages: data.pages.map((pageData) => {
                    return {
                        ...pageData,
                        data: pageData.data.filter((post) => post.live_streaming_id !== id)
                    };
                })
            };
        }

        return data;
    });

    queryClient.setQueriesData([queryKeys.livestreams], (data) => data.id !== id);

    queryClient.setQueriesData([queryKeys.searchLivestream], (data) => {
        if (data?.pages) {
            return {
                ...data,
                pages: data.pages.map((pageData) => {
                    return {
                        ...pageData,
                        data: pageData.data.filter((post) => post.id !== id)
                    };
                })
            };
        }

        return data;
    });
};

/**
 * Updates Livestream
 * @param {Object} data
 * @returns {void}
 */
export const updateLivestreamCache = (data) => {
    queryClient.setQueriesData([queryKeys.feedPosts], (post) => {
        if (post?.pages) {
            return {
                ...post,
                pages: post.pages.map((pageData) => {
                    return {
                        ...pageData,
                        data: pageData.data.map((item) => {
                            if (item.live_streaming_id === data.id) {
                                return {
                                    ...item,
                                    live_streaming: {
                                        ...item.live_streaming,
                                        ...data
                                    }
                                };
                            }

                            return item;
                        })
                    };
                })
            };
        }

        return post;
    });

    queryClient.setQueriesData([queryKeys.searchLivestream], (livestream) => {
        if (livestream?.pages) {
            return {
                ...livestream,
                pages: livestream.pages.map((pageData) => {
                    return {
                        ...pageData,
                        data: pageData.data.map((item) => {
                            if (item.id === data.id) {
                                return {
                                    ...item,
                                    ...data
                                };
                            }

                            return item;
                        })
                    };
                })
            };
        }

        return data;
    });

    // Post Livestream List
    if (parseInt(data.status, 10) > 2) {
        queryClient.setQueriesData([queryKeys.livestreams], (livestreams) => {
            return livestreams.filter((livestream) => livestream.id !== data.id);
        });
    } else if (parseInt(data.status, 10) === 2) {
        queryClient.setQueriesData([queryKeys.livestreams], (livestreams) => {
            if (parseInt(data.type, 10) === 2) {
                // Do not do any update if the type === 2 (practice livestream)
                return livestreams;
            }

            const exists = livestreams?.find((livestream) => livestream.id === data.id);

            if (!exists) {
                return [...livestreams, data];
            }

            return livestreams.map((livestream) => {
                return livestream.id === data.id
                    ? { ...livestream, ...data }
                    : livestream;
            });
        });
    }
};

/**
 * Add livestream comment
 * @param {Object} comment
 */
export function addLivestreamComment(comment) {
    queryClient.setQueriesData([queryKeys.feedPosts], (post) => {
        if (post?.pages) {
            return {
                ...post,
                pages: post.pages.map((pageData) => {
                    return {
                        ...pageData,
                        data: pageData.data.map((item) => {
                            if (item.live_streaming_id === comment.live_streaming_id) {
                                const currentComments = item.live_streaming.comments || [];
                                const existingComment = currentComments?.find((currentComment) => currentComment.id === comment.id);

                                if (existingComment) {
                                    return item;
                                }

                                item.live_streaming.comments = [...currentComments, comment];
                                item.live_streaming.comments_count = (item.live_streaming.comments_count || 0) + 1;
                            }

                            return item;
                        })
                    };
                })
            };
        }

        return post;
    });
}
