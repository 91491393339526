import { QueryClient } from '@tanstack/react-query';

export const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            cacheTime: 1000 * 60 * 60 // 1 hour
        }
    }
});

export const queryKeys = {
    challengesTab: 'challengesTab',
    challenges: 'challenges',
    challengeSubmissions: 'challengeSubmissions',
    challengeWinnersSubmissions: 'challengeWinnersSubmissions',
    cookTopCollection: 'cookTopCollection',
    occasion: 'occasion',
    livestreams: 'livestreams',
    searchLivestream: 'searchLivestream',
    allEvents: 'allEvents'
};

export const gcQueryKeys = {
    joinnableRooms: 'joinnableRooms',
    searchRooms: 'searchRooms',
    roomMembers: 'roomMembers',
    room: 'room'
};
