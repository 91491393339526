const search = {
    en: {
        RECENT_SEARCHES: 'Recent Search',
        POPULAR_SEARCHES: 'Popular Search',
        SEARCH_ALL: 'All',
        SEARCH_RECIPES: 'Recipes',
        SEARCH_CHALLENGES: 'Challenges',
        SEARCH_CREATORS: 'Creators',
        SEARCH_EMPTY: 'We cannot find matches for your search term. Try another?',
        SEARCH_VIEW_MORE: 'View More'
    },
    id: {
        RECENT_SEARCHES: 'Pencarian Terakhir',
        POPULAR_SEARCHES: 'Pencarian Populer',
        SEARCH_ALL: 'Semua',
        SEARCH_RECIPES: 'Resep',
        SEARCH_CHALLENGES: 'Tantangan',
        SEARCH_CREATORS: 'Kreator',
        SEARCH_EMPTY: 'Tidak ada hasil yang ditemukan. Coba cari sesuatu yang lain.',
        SEARCH_VIEW_MORE: 'Lihat Lainnya'
    }
};

export default search;
