const general = {
    en: {
        GENERAL_REPORT: 'Report',
        GENERAL_CANCEL: 'Cancel',
        GENERAL_SEND: 'Send',
        GENERAL_SAVE: 'Save',
        GENERAL_CONTINUE: 'Continue',
        GENERAL_BACK_TO: 'Back to',
        GENERAL_SHARE: 'Share',
        GENERAL_DOWNLOAD: 'Download',
        GENERAL_DELETE: 'Delete',
        GENERAL_SEE_ALL: 'See All',

        REPORT_HEADING: 'Why are you reporting this post?',
        REPORT_DUPLICATE: 'Duplicate Post',
        REPORT_NOT_HOMEMADE: 'Not Homemade',
        REPORT_FRAUD: 'Fraud/Inpropriate',
        REPORT_OTHER: 'Other',
        REPORT_MESSAGE_PALCEHOLDER: 'Let me know what’s happening and we’ll lok at it',
        REPORT_MESSAGE: 'Thank you, Your report has been submitted'
    },
    id: {
        GENERAL_REPORT: 'Laporkan',
        GENERAL_CANCEL: 'Batal',
        GENERAL_SEND: 'Kirim',
        GENERAL_SAVE: 'Simpan',
        GENERAL_CONTINUE: 'Lanjutkan',
        GENERAL_BACK_TO: 'Kembali ke',
        GENERAL_SHARE: 'Bagikan',
        GENERAL_DOWNLOAD: 'Download',
        GENERAL_DELETE: 'Hapus',
        GENERAL_SEE_ALL: 'Lihat lainnya',

        REPORT_HEADING: 'Kenapa Anda melaporkan postingan ini?',
        REPORT_DUPLICATE: 'Duplikat Konten',
        REPORT_NOT_HOMEMADE: 'Bukan Buatan Rumah',
        REPORT_FRAUD: 'Penipuan / Konten Tidak Pantas',
        REPORT_OTHER: 'Lainnya',
        REPORT_MESSAGE_PALCEHOLDER: 'Beri tahu kami apa yang terjadi dan kami akan selidiki hal itu.',
        REPORT_MESSAGE: 'Terima Kasih, Laporanmu telah terkirim'
    }
};

export default general;
