import branch from 'branch-sdk';
import { APP_LANGUAGE, dateTypeChip, imageEndpoints, LOGIN_TOKEN_STORAGE_KEY } from 'config/constants';
import { differenceInHours, format, formatDistanceToNowStrict, isAfter, isSameDay, isWithinInterval, parseISO } from 'date-fns';
import { getRecipeImage } from 'utils/recipeHelpers';

let axiosTokenInterceptor = null;

/**
 * Save login token
 * @param {String} loginToken
 */
export async function saveLoginToken(loginToken) {
    await localStorage.setItem(LOGIN_TOKEN_STORAGE_KEY, loginToken);
}

/**
 * Retrieve login token
 * @returns {String}
 */
export async function getLoginToken() {
    const token = await localStorage?.getItem(LOGIN_TOKEN_STORAGE_KEY);

    return token;
}

/**
 * Delete login token
 */
export async function deleteLoginToken() {
    localStorage.removeItem(LOGIN_TOKEN_STORAGE_KEY);
    window._axios.interceptors.request.eject(axiosTokenInterceptor);
}

/**
 * Save login token
 * @param {String} lan
 */
export async function saveLanguage(lan) {
    await localStorage.setItem(APP_LANGUAGE, lan);
}

/**
 * Retrieve login token
 * @returns {String}
 */
export async function getLanguage() {
    const language = await localStorage?.getItem(APP_LANGUAGE);

    return language;
}

/**
 * Sets the the authorization token for api requests
 * @param {String} token
 */
export function setApiRequestToken(token) {
    axiosTokenInterceptor = window._axios.interceptors.request.use((config) => {
        config.headers.Authorization = `Bearer ${token || ''}`;

        return config;
    });
}

/**
 * Validate email address
 * @param {String} email
 * @returns {Boolean}
 */
export function validateEmail(email) {
    // eslint-disable-next-line no-useless-escape
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    return re.test(email);
}

/**
 * Formats date into relative (e.g 2H)
 * @param {String} date
 * @returns {String}
 */
export function formatDate(date) {
    const parsedDate = parseISO(date);
    const formattedDate = formatDistanceToNowStrict(parsedDate, { roundingMethod: 'floor' });
    const [amount, distanceInWords] = formattedDate.split(' ');

    if (['seconds', 'minutes', 'hours'].includes(distanceInWords)) {
        return `${amount}${distanceInWords[0]}`;
    }

    return format(parsedDate, 'MM/dd/yy');
}

/**
 * Computes ingredients quantity
 * @param {Object} params
 * @returns {Number}
 */
export function computeQuantity(params) {
    if (params.quantity) {
        /**
     * Some quantity is fraction. e.g (1/2)
     * Check if quantity contains `/` to check if it's fraction.
     */
        if (params.quantity.includes('/')) {
            /**
             * Split the quantity by blank space, just in case it's a mixed fraction
             * e.g. 3 1/2
             */
            const splittedMixedFraction = params.quantity.split(' ');

            let totalQuantity = 0;

            splittedMixedFraction.forEach(number => {
                // eslint-disable-next-line no-eval
                return (totalQuantity += eval(number));
            });

            totalQuantity = Math.round(totalQuantity * 100) / 100;

            const total = params.servings * (totalQuantity / params.originalServings);

            return Math.round(total * 100) / 100;
        } else {
            const total = params.servings * (params.quantity / params.originalServings);

            return Math.round(total * 100) / 100;
        }
    }

    return '';
}

/**
 * Get user OS to detect what store to open
 * @returns {String}
 */
export function getOS() {
    const userAgent = window.navigator.userAgent;
    const platform = window.navigator.platform;
    const macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'];
    const windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'];
    const iosPlatforms = ['iPhone', 'iPad', 'iPod'];
    let os = null;

    if (macosPlatforms.indexOf(platform) !== -1) {
        os = 'Mac OS';
    } else if (iosPlatforms.indexOf(platform) !== -1) {
        os = 'iOS';
    } else if (windowsPlatforms.indexOf(platform) !== -1) {
        os = 'Windows';
    } else if (/Android/.test(userAgent)) {
        os = 'Android';
    } else if (!os && /Linux/.test(platform)) {
        os = 'Linux';
    }

    return os;
}

/**
 * Generate Recipe Structure Data
 * @param {Object} recipe
 * @returns {Object}
 */
export function generateRecipeStructureData(recipe) {
    /**
     * To add:
     * - datePublished
     * - keywords
     * - aggregateRating
     *
     */

    const nutrition = {
        '@type': 'NutritionInformation',
        calories: `${Math.round(recipe.calories * 10) / 10} calories`,
        carbohydrateContent: `${Math.round(recipe.carbs * 100) / 100} carbohydrates`,
        fiberContent: `${Math.round(recipe.fiber * 100) / 100} fiber`,
        proteinContent: `${Math.round(recipe.protein * 100) / 100} protein`,
        saturatedFatContent: `${Math.round(recipe.saturatedfat * 100) / 100} saturated fat`,
        fatContent: `${Math.round(recipe.totalfat * 100) / 100} fat`
    };

    const recipeIngredient = recipe.ingredients.map((ingerdient) => {
        const quantity = computeQuantity({
            quantity: ingerdient.pivot.quantity,
            servings: recipe.servings,
            originalServings: recipe.servings
        });

        return `${quantity} ${ingerdient.pivot.serving} ${ingerdient.name}`;
    });

    const recipeInstructions = JSON.parse(recipe.instructions).map((instruction, index) => {
        return {
            '@type': 'HowToStep',
            name: `Step ${index + 1}`,
            text: instruction
        };
    });

    return {
        '@context': 'https://schema.org/',
        '@type': 'Recipe',
        name: recipe.recipename,
        image: [getRecipeImage(recipe.is_uploaded, recipe.image_name, recipe.images, recipe.video_thumbnail)],
        author: {
            '@type': 'Person',
            name: recipe.publisher.name
        },
        description: recipe.description,
        prepTime: `PT${recipe.preptime}M`,
        cookTime: `PT${recipe.cookingtime}M`,
        totalTime: `PT${recipe.total_time}M`,
        recipeYield: recipe.servings,
        nutrition,
        recipeIngredient,
        recipeInstructions,
        recipeCuisine: recipe.cuisines.map((cuisine) => cuisine.name).join(', '),
        recipeCategory: recipe.meals.map((meal) => meal.name).join(', '),
        aggregateRating: {
            '@type': 'AggregateRating',
            ratingValue: recipe.rating
        },
        keywords: recipe.keywords
    };
}

/**
 * Calculate challenge date type chip
 * @param {String} startDate
 * @param {String} endDate
 * @returns {Object}
 */
export function getChallengeDateChip(startDate, endDate) {
    const currentDate = new Date();
    const startDateDifference = startDate ? Math.floor(differenceInHours(currentDate, new Date(startDate)) / 24) : null;
    const endDateDifference = endDate ? Math.ceil(differenceInHours(new Date(endDate), currentDate) / 24) : null;

    if (startDateDifference < 0) {
        return { type: dateTypeChip.starting, count: Math.abs(startDateDifference) };
    }

    if (!endDate) {
        return { type: dateTypeChip.active, count: 0 };
    }

    if (endDateDifference >= 0) {
        return { type: dateTypeChip.ending, count: endDateDifference };
    }

    return { type: dateTypeChip.completed, count: 0 };
}

/**
 * Calculate event date type chip
 * @param {String} startDate
 * @param {String} endDate
 * @returns {Object} {type: dateTypeChip, sameDay: bool}
 */
export const handleGetEventTimeType = (startDate, endDate) => {
    let type = '';
    const sameDay = isSameDay(new Date(startDate), new Date(endDate));

    if (isAfter(new Date(), new Date(endDate))) {
        type = dateTypeChip.ending;
    } else if (isWithinInterval(new Date(), { start: new Date(startDate), end: new Date(endDate) })) {
        type = dateTypeChip.starting;
    } else {
        type = dateTypeChip.active;
    }

    return { type, sameDay };
};

/**
 * Abbreviate long numbers
 * @param {Number} value
 * @returns {String}
 */
export function abbreviateNumber(value) {
    const valueLength = ('' + value).length;

    if (valueLength < 4) {
        return `${value}`;
    } else {
        const suffix = ['K', 'M', 'B', 'T'];
        const index = Math.ceil((valueLength - 3) / 3);

        return (value / Math.pow(1000, index)).toFixed(1) + suffix[index - 1];
    }
}

/**
 * Determine the mobile operating system.
 * This function returns one of 'iOS', 'Android', 'Windows Phone', or 'unknown'.
 *
 * @returns {String}
 */
export function getMobileOperatingSystem() {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;

    if (/windows phone/i.test(userAgent)) {
        return 'Windows Phone';
    }

    if (/android/i.test(userAgent)) {
        return 'Android';
    }

    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
        return 'iOS';
    }

    return 'unknown';
}

/**
 * Get language based on country
 * @param {String} country
 * @returns {String}
 */
export function getCountryLanguage(country) {
    return country?.toLowerCase() === 'id' ? 'id' : 'en';
}

/**
 * Generate branch url
 * @param {Object} configuration
 * @param {Function} callback
 * @returns {Promise}
 */
export const generateBranchUrl = async (configuration, callback) => {
    const {
        title,
        description = '',
        image = imageEndpoints.logoUrl + 'YoRipe_logo2.png',
        linkProperties = {},
        controlParams = {}
    } = configuration;

    try {
        const linkData = {
            data: {
                custom_bool: true,
                custom_int: Date.now(),
                $og_title: title,
                $og_description: description,
                $og_image_url: image,
                ...controlParams
            }
        };

        branch.link(linkData, (err, link) => {
            if (!err) {
                callback(link);
            }
        });
    } catch (error) {
        if (error.code === 'RNBranch::Error::DuplicateResourceError') {
            // eslint-disable-next-line node/no-callback-literal
            callback(`https://yoripeapp.app.link/${linkProperties.alias}`);
        }
    }
};

// parse JWT tokens
export function parseJwt(token) {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    return JSON.parse(jsonPayload);
}

/**
 * add anchor if text contains links
 * @param {String} text
 * @returns {String}
 */
export const detectLink = (text) => {
    const textWithlink = text.replace(/(((https?:\/\/)|(www\.))[^\s]+)/gi, (url) => {
        return '<a target="_blank" href="' + url + '">' + url + '</a>';
    });

    return textWithlink;
};
