const error = {
    en: {
        ERROR_TITLE: 'Something went wrong here.',
        ERROR_DESCRIPTION: 'we’re keep looking for the problem,  try to refresh the page if still not fixed feel free to contact us',
        ERROR_BUTTON: 'Reload',
        ERROR_NOT_FOUND: 'Page not Found',
        ERROR_HOME: 'Back to Home',
        ERROR_BANNER: 'An error occured. Failed to fetch banners',
        ERROR_LANGUAGE: 'Something went wrong',
        ERROR_STORY: 'An error occured. Failed to fetch Story',
        ERROR_STORIES: 'An error occured. Failed to fetch stories',
        ERROR_RECIPE: 'An error occured. Failed to fetch Recipe',
        ERROR_CHALLENGES: 'An error occured. Failed to fetch challenges',
        ERROR_REVIEW: 'An error occured. Failed to fetch Review',
        ERROR_FACEBOOK_SIGNUP: 'Email is registered via Facebook. Please use facebook login.',
        ERROR_GOOGLE_SIGNUP: 'Email is registered via Google. Please use facebook Google.',
        ERROR_SIGNUP_EMAIL: 'Email already registered, Try login',
        ERROR_LOGIN_FACEBOOK: 'Please connect by using Facebook login',
        ERROR_LOGIN_GOOGLE: 'Please connect by using Google login',
        ERROR_EMAIL_404: 'Unable to find your email. Make sure you entered a registered email.',
        ERROR_RECOVERY: 'Failed to send recovery password.',
        ERROR_LOGIN: 'Failed to sign in',
        INVALID_EMAIL: 'Invalid Email',
        INVALID_PASSWORD: 'Please Enter Password',
        CONFIRM_PASSWORD_ERROR: 'The password confirmation does not match.',
        INVALID_PHONE: 'Invalid Phone Number',
        INVALID_OTP: 'Invalid OTP',
        INVALID_EMAIL2: 'Please use a valid Email',
        INVALID_NAME: 'Please enter your Name',
        INVALID_USERNAME: 'Username cannot be empty',
        INVALID_ADDRESS: 'Please enter your location',
        INVALID_IMAGE: 'Please upload a profile picture'
    },
    id: {
        ERROR_TITLE: 'Ada sesuatu yang bermasalah disini.',
        ERROR_DESCRIPTION: 'Kami masih mencari sesuatu yang bermasalah, silahkan coba untuk muat ulang halaman jika masih tetap bermasalah jangan ragu untuk hubungi kami',
        ERROR_BUTTON: 'Memuat kembali',
        ERROR_NOT_FOUND: 'Halaman Tidak Ditemukan',
        ERROR_HOME: 'Kembali ke Halaman Awal',
        ERROR_BANNER: 'Terjadi kesalahan. Gagal memuat gambar',
        ERROR_LANGUAGE: 'Ada sesuatu yang bermasalah',
        ERROR_STORY: 'Terjadi kesalahan. Gagal memuat Cerita',
        ERROR_STORIES: 'Terjadi kesalahan. Gagal memuat Cerita',
        ERROR_RECIPE: 'Terjadi kesalahan. Gagal memuat Resep',
        ERROR_CHALLENGES: 'Terjadi kesalahan. Gagal memuat Tantangan Seru',
        ERROR_REVIEW: 'Terjadi kesalahan. Gagal memuat Ulasan',
        ERROR_FACEBOOK_SIGNUP: 'Email telah terdaftar melalui Facebook. Silahkan masuk menggunakan Facebook.',
        ERROR_GOOGLE_SIGNUP: 'Email telah terdaftar melalui Google. Silahkan masuk menggunakan Google.',
        ERROR_SIGNUP_EMAIL: 'Email sudah terdaftar, Silahkan coba untuk masuk',
        ERROR_LOGIN_FACEBOOK: 'Silahkan terhubung masuk menggunakan Facebook',
        ERROR_LOGIN_GOOGLE: 'Silahkan terhubung masuk menggunakan Google',
        ERROR_EMAIL_404: 'Email anda tidak ditemukan. Pastikan anda memasukkan email yang telah terdaftar.',
        ERROR_RECOVERY: 'Gagal mengirimkan kata sandi pemulihan.',
        ERROR_LOGIN: 'Gagal masuk',
        INVALID_EMAIL: 'Email Tidak Valid',
        INVALID_PASSWORD: 'Silahkan Masukkan Kata Sandi',
        CONFIRM_PASSWORD_ERROR: 'Konfirmasi kata sandi tidak cocok.',
        INVALID_PHONE: 'Nomor Handphone Tidak Valid',
        INVALID_OTP: 'OTP Tidak Valid',
        INVALID_EMAIL2: 'Silahkan gunakan Email yang valid',
        INVALID_NAME: 'Nama pengguna tidak boleh kosong',
        INVALID_USERNAME: 'Please enter your Username',
        INVALID_ADDRESS: 'Silakan masukkan lokasi Anda',
        INVALID_IMAGE: 'Silahkan upload foto profil'
    }
};

export default error;
