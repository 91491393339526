const UPDATE_RECIPE = 'UPDATE_RECIPE';
const UPDATE_RECIPES = 'UPDATE_RECIPES';

const UPDATE_RECIPES_COMMENT = 'UPDATE_RECIPES_COMMENT';
const UPDATE_RECIPES_COMMENTS = 'UPDATE_RECIPES_COMMENTS';

function setRecipes(recipes) {
    return {
        type: UPDATE_RECIPES,
        recipes
    };
}

function setComments(comments) {
    return {
        type: UPDATE_RECIPES_COMMENTS,
        comments
    };
}

export function updateRecipes(recipes) {
    return async function(dispatch, getState) {
        const currentRecipes = JSON.parse(JSON.stringify(getState().recipes.recipes));

        const recipeObject = recipes.reduce((obj, item) => {
            obj[item.id] = {
                ...obj[item.id],
                ...item
            };

            return obj;
        }, currentRecipes);

        dispatch(setRecipes(recipeObject));

        const recipeComments = recipes.map((recipe) => recipe.recipe_comments || []).flat();

        if (recipeComments.length) {
            dispatch(updateRecipeComments(recipeComments));
        }
    };
}

export function updateRecipe(recipe) {
    return async function(dispatch, getState) {
        const recipeObject = getState().recipes.recipes[recipe.id] || {};

        dispatch({
            type: UPDATE_RECIPE,
            recipe: {
                ...recipeObject,
                ...recipe
            }
        });
    };
}

export function addRecipeComments(comments) {
    return async function(dispatch) {
        dispatch(updateRecipeComments(comments));
    };
}

export function updateRecipeComments(comments) {
    return async function(dispatch, getState) {
        const currentComments = JSON.parse(JSON.stringify(getState().recipes.comments));
        const commentObject = comments.reduce((obj, item) => {
            obj[item.id] = {
                ...obj[item.id],
                ...item
            };

            return obj;
        }, currentComments);

        dispatch(setComments(commentObject));
    };
}

export function deleteComment(recipeId, commentId) {
    return async function(dispatch, getState) {
        const comments = JSON.parse(JSON.stringify(getState().recipes.comments));

        delete comments[commentId];

        dispatch(setComments(comments));
    };
}

const initialState = {
    recipes: {},
    comments: {}
};

export default function recipes(state = initialState, action) {
    switch (action.type) {
        case UPDATE_RECIPES:
            return {
                ...state,
                recipes: action.recipes
            };
        case UPDATE_RECIPE:
            return {
                ...state,
                recipes: {
                    ...state.recipes,
                    [action.recipe.id]: action.recipe
                }
            };

        case UPDATE_RECIPES_COMMENT:
            return {
                ...state,
                comments: {
                    ...state.comments,
                    [action.comment.id]: action.comment
                }
            };

        case UPDATE_RECIPES_COMMENTS:
            return {
                ...state,
                comments: action.comments
            };
        default:
            return state;
    }
}
