const profile = {
    en: {
        PROFILE_SEE_ALL: 'See All',
        PROFILE_RECENT: 'Recently Viewed',
        PROFILE_RECENT_DESCRIPTION: 'Last content you view',
        PROFILE_SAVED_RECIPES: 'Saved Recipes',
        PROFILE_SAVED_RECIPES_DESCRIPTION: 'Recipes you’ve save.',
        PROFILE_SAVED_STORIES: 'Saved Stories',
        PROFILE_SAVED_STORIES_DESCRIPTION: 'Stories you’ve save.',
        PROFILE_RECIPES: 'My Creations - Recipes',
        PROFILE_RECIPES_DESCRIPTION: 'Recipes you have posted',
        PROFILE_STORIES: 'My Creations - Cooking Stories',
        PROFILE_STORIES_DESCRIPTION: 'Stories you posted',
        PROFILE_RETURN: 'Back to Profile',
        PROFILE_SUBMIT: 'Submit your Recipes'
    },
    id: {
        PROFILE_SEE_ALL: 'Lihat Semua',
        PROFILE_RECENT: 'Baru Dilihat',
        PROFILE_RECENT_DESCRIPTION: 'Post yang baru saja kamu lihat',
        PROFILE_SAVED_RECIPES: 'Resep disimpan',
        PROFILE_SAVED_RECIPES_DESCRIPTION: 'Resep-resep yang kamu simpan.',
        PROFILE_SAVED_STORIES: 'Cerita disimpan',
        PROFILE_SAVED_STORIES_DESCRIPTION: 'Cerita yang kamu simpan.',
        PROFILE_RECIPES: 'Buatan Saya - Resep',
        PROFILE_RECIPES_DESCRIPTION: 'Resep yang sudah kamu unggah.',
        PROFILE_STORIES: 'Buatan Saya - Cerita Memasak',
        PROFILE_STORIES_DESCRIPTION: 'Cerita yang kamu unggah.',
        PROFILE_RETURN: 'Kembali ke Profil',
        PROFILE_SUBMIT: 'Unggah Resep Anda'
    }
};

export default profile;
