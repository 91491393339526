import './config/i18n';

import loadable from '@loadable/component';
import { GoogleOAuthProvider } from '@react-oauth/google';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import branch from 'branch-sdk';
import { GA_KEY, googleClientIds, googleRecaptchSiteKey } from 'config/constants';
import { ConnectedRouter } from 'connected-react-router';
import App from 'containers/App';
import React from 'react';
import ReactDOM from 'react-dom';
import ReactGA from 'react-ga4';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import TagManager from 'react-gtm-module';
import { Provider } from 'react-redux';
import store, { history } from 'utils/redux';

import reportWebVitals from './reportWebVitals';

const SomethingWrong = loadable(() => import('components/SomethingWrong'));

Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    integrations: [new Integrations.BrowserTracing()],
    autoSessionTracking: false,
    environment: process.env.REACT_APP_ENV
});

branch.init('key_live_ckIzmlzYoK1Qnm0tc2BvkkgoByog1Fi3');

// const options = {
//     autoConfig: true,
//     debug: false // enable logs
// };

const tagManagerArgs = {
    gtmId: 'GTM-559FLW4',
    dataLayerName: 'LPDataLayer'
};

TagManager.initialize(tagManagerArgs);

// ReactPixel.init('539802963948098', options);

window._axios.defaults.baseDomain = process.env.REACT_APP_API_URL;
window._axios.defaults.baseURL = process.env.REACT_APP_API_URL;
window._axios.defaults.headers.common.channel = 'web';

ReactGA.initialize(GA_KEY);

const queryClient = new QueryClient();

ReactDOM.render(
    <React.StrictMode>
        <Provider store={store}>
            <GoogleReCaptchaProvider reCaptchaKey={googleRecaptchSiteKey}>
                <GoogleOAuthProvider clientId={googleClientIds.webClientId}>
                    <QueryClientProvider client={queryClient}>
                        <ConnectedRouter history={history}>
                            <Sentry.ErrorBoundary fallback={<SomethingWrong />}>
                                <App />
                            </Sentry.ErrorBoundary>
                        </ConnectedRouter>
                    </QueryClientProvider>
                </GoogleOAuthProvider>
            </GoogleReCaptchaProvider>
        </Provider>
    </React.StrictMode>,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
