const events = {
    en: {
        EVENTS_TITLE: 'Events',
        EVENTS_LIST_TITLE: 'YoRipe\'s Events',
        EVENTS_META_DESC: 'Ready for some exciting events? Explore our featured events and engage to our community to make more friends!',
        EVENTS_DESCRIPTION: 'Indulge in a cooking & baking adventure with our diverse events!',
        EVENT_NOT_FOUND: 'No events found at the moment.\nDon\'t worry, there\'s always more to come. Let\'s discover other activities for a delightful experience!',
        JOINED: 'Joined',
        INTERESED: 'interested',
        VIEWS: 'views',
        HAPPENING_NOW: 'HAPPENING NOW',
        EVENT_ENDED: 'Event Ended',
        EVENT_NOT_AVAILABLE: 'There are no available event',
        EVENT_NOT_AVAILABLE_DESC: 'We are preparing an interesting event for you soon, stay tuned!'
    },
    id: {
        EVENTS_TITLE: 'Acara',
        EVENTS_LIST_TITLE: 'Acara di YoRipe',
        EVENTS_META_DESC: 'Lagi nyari kegiatan yang seru? Yuk ikutan berbagai acara komunitas sekalian nambah teman di YoRipe!',
        EVENTS_DESCRIPTION: 'Ikuti keseruan masak bareng dengan komunitas.',
        EVENT_NOT_FOUND: 'Yah.. kami ga bisa nemuin acara ini. \nCoba cek acara atau aktivitas lainnya di YoRipe yuk.',
        JOINED: 'Bergabung',
        INTERESED: 'tertarik',
        VIEWS: 'pengunjung',
        HAPPENING_NOW: 'SEDANG BERLANGSUNG',
        EVENT_ENDED: 'Acara Berakhir',
        EVENT_NOT_AVAILABLE: 'Belum ada acara saat ini',
        EVENT_NOT_AVAILABLE_DESC: 'Kami sedang mempersiapkan acara yang menarik untukmu, tunggu dan nantikan ya'
    }
};

export default events;
