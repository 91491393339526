import { getAuthedUserProfile } from 'api/users';
import axios from 'axios';
import i18next from 'i18next';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { getCountryLanguage, getLanguage, getLoginToken, saveLanguage, setApiRequestToken } from 'utils/helpers';
import { isAuthed, switchCountry } from 'utils/redux/modules/authentication';
import { updateRecipes } from 'utils/redux/modules/recipes';

function useAppPreparation() {
    const [isAppReady, setAppReady] = useState(false);
    const dispatch = useDispatch();

    useEffect(() => {
        fetchCountry();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const loadAuthedData = useCallback(async () => {
        try {
            const loginToken = await getLoginToken();

            if (loginToken) {
                setApiRequestToken(loginToken);

                const profileData = await getAuthedUserProfile();

                if (profileData.data.recipes && profileData.data.recipes.length > 0) {
                    dispatch(updateRecipes(profileData.data.recipes));
                }

                if (profileData.data.onboarding_data) {
                    dispatch(isAuthed({ data: profileData.data, onboardingData: JSON.parse(profileData.data.onboarding_data) }));
                } else {
                    dispatch(isAuthed({ data: profileData.data }));
                }
            }
        } finally {
            setAppReady(true);
        }
    }, [dispatch]);

    const fetchCountry = useCallback(async () => {
        const storedLanguage = await getLanguage();

        try {
            const response = await axios.get('https://get.geojs.io/v1/ip/country.json');
            const language = getCountryLanguage(response.data.country);

            window._axios.defaults.headers.common.language = language || 'en';
            dispatch(switchCountry(language));

            if (storedLanguage) {
                i18next.changeLanguage(storedLanguage);
            } else {
                i18next.changeLanguage(language);
                saveLanguage(response.data.country);
            }
        } finally {
            loadAuthedData();
        }
    }, [dispatch, loadAuthedData]);

    return isAppReady;
}

export default useAppPreparation;
