const login = {
    en: {
        LOGIN_TITLE: 'Welcome to yoripe',
        SIGNUP_TITLE: 'Sign up to cook, eat better & connect with like-minded homecooks',
        LOGIN_DESCRIPTION: 'Plan your week, shop for groceries and share your cooking. Please log in. :) ',
        LOGIN_EMAIL: 'Email',
        LOGIN_PHONE: 'Phone',
        LOGIN_PASSWORD: 'Password',
        LOGIN_FORGOT: 'Forgot Password?',
        LOGIN_VIA_PASSWORD: 'Login via Password',
        OTP_VERITY: 'Submit',
        ENTER_OTP: 'Please enter your 4 digit OTP. which has been sent to ',
        ENTER_WHATSAPP_OTP: 'Enter the OTP code sent via WhatsApp to your registered phone number ',
        OTP_WAIT_COUNTER: 'Please wait within {timer} seconds for resend',
        CODE_NOT_RECEIVE: 'Didn\'t receive the code?',
        RESEND: 'Resend',
        CONTINUE_WITH: 'Continue with',
        NO_ACCOUNT: "Don't have an account?",
        HAVE_ACCOUNT: 'I have an account?',
        LOGIN_BUTTON: 'Sign In with Email',
        SIGNUP_BUTTON: 'Sign Up with Email',
        LOGIN_REGISTER: 'Sign Up',
        SIGN_IN: 'Sign in',
        LOGIN_SOCIAL: 'Or Sign In with',
        SIGNUP_SOCIAL: 'Or Sign Up with',
        LOGIN_DISCLAIMER1: 'By signing up, I accept the',
        LOGIN_DISCLAIMER2: 'Terms of Use',
        LOGIN_DISCLAIMER3: 'and',
        LOGIN_DISCLAIMER4: 'Privacy Notice',
        LOGIN_DISCLAIMER5: 'We do not post on your social media account or share your details',
        FORGOT_EMAIL_TITLE: 'Input your email',
        FORGOT_EMAIL_DESCRIPTION: 'We will send you the new link for reset your Passowrd',
        FORGOT_EMAIL_BUTTON: 'Next',
        FORGOT_CHECK_TITLE: 'Check your Email',
        FORGOT_CHECK_DESCRIPTION: 'we’ve sent you link for reseting you email. please check your inbox or spam.',
        FORGOT_CHECK_BUTTON: 'Back To Home'
    },
    id: {
        LOGIN_TITLE: 'Selamat Datang di YoRipe!',
        SIGNUP_TITLE: 'Daftar untuk memasak, makan dengan lebih baik dan terhubung dengan koki rumahan dengan hobi yang sama',
        LOGIN_DESCRIPTION: 'Buat rencana mingguan anda, belanja bahan makanan dan bagikan masakan anda. Silahkan masuk. :)',
        LOGIN_EMAIL: 'Email',
        LOGIN_PHONE: 'Phone',
        LOGIN_PASSWORD: 'Kata Sandi',
        LOGIN_FORGOT: 'Lupa Kata Sandi?',
        LOGIN_VIA_PASSWORD: 'Masuk dengan kata sandi',
        CONTINUE_WITH: 'Lanjutkan dengan',
        NO_ACCOUNT: 'Saya tidak punya akun?',
        HAVE_ACCOUNT: 'Saya sudah punya akun?',
        LOGIN_BUTTON: 'Masuk dengan Email',
        OTP_VERIFY: 'Kirim',
        ENTER_OTP: 'Silakan masukkan 4 digit OTP Anda yang dikirim ke nomor ini ',
        ENTER_WHATSAPP_OTP: 'Masukkan kode OTP yang terkirim ke WhatsApp Anda ',
        OTP_WAIT_COUNTER: 'Harap tunggu dalam {timer} detik untuk mengirim ulang',
        CODE_NOT_RECEIVE: 'Tidak menerima kode?',
        RESEND: 'Kirim Ulang',
        SIGNUP_BUTTON: 'Daftar Dengan Email',
        LOGIN_REGISTER: 'Daftar',
        SIGN_IN: 'Masuk',
        LOGIN_SOCIAL: 'Atau Masuk Dengan',
        SIGNUP_SOCIAL: 'ATAU DAFTAR DENGAN EMAIL',
        LOGIN_DISCLAIMER1: 'Dengan mendaftar, saya menerima',
        LOGIN_DISCLAIMER2: 'Ketentuan Penggunaan',
        LOGIN_DISCLAIMER3: 'dan',
        LOGIN_DISCLAIMER4: 'Pemberitahuan Privasi',
        LOGIN_DISCLAIMER5: 'Kami tidak memposting di akun sosial media anda atau membagikan detail anda',
        FORGOT_EMAIL_TITLE: 'Masukkan email anda',
        FORGOT_EMAIL_DESCRIPTION: 'Kami akan mengirimkan anda tautan baru untuk mengatur ulang Kata Sandi anda',
        FORGOT_EMAIL_BUTTON: 'Lanjut',
        FORGOT_CHECK_TITLE: 'Cek Email anda',
        FORGOT_CHECK_DESCRIPTION: 'Kami telah mengirimkan anda tautan untuk mengatur ulang email anda. Silahkan cek kotak masuk atau spam anda.',
        FORGOT_CHECK_BUTTON: 'Kembali ke Halaman Awal'
    }
};

export default login;
