const download = {
    en: {
        DOWNLOAD_TITLE: 'Get better experiences, extra perks & lots fun!',
        DOWNLOAD_DESCRIPTION: 'Use the YoRipe app on iOS or Android',
        DOWNLOAD_APPSTORE: 'Download in Appstore',
        DOWNLOAD_PLAYSTORE: 'Download in Playstore',
        DOWNLOAD_BANNER: 'Download the app to participate challenge!',
        YELLOW_TITLE: 'Connect with home cooks, creators, & brands based on your interests!',
        YELLOW_PT1: 'Discover 10k+ recipes by local home cooks',
        YELLOW_PT2: 'Join vibrant groups to share & learn',
        YELLOW_PT3: 'Participate in challenges to win prizes',
        MOBILE_TITLE: 'Download YoRipe for Free!',
        MOBLIE_DESCRIPTION: 'When you join us, you gain access to:',
        MOBILE_PT1: 'Join Challenge and win prize',
        MOBILE_PT2: 'Join our community of like-minded Chefs',
        MOBILE_PT3: 'Share your Cooking with the Community',
        MOBILE_BUTTON: 'Download YoRipe',
        DOWNLOAD_APP: 'Download app',
        SMART_BANNER: 'We are more AWESOME in the app',
        GET_ON_PLAY: 'GET – On the Google Play',
        GET_ON_APPSTORE: 'GET – On the App Store',
        SEE_IN_APP: 'See this page in...',
        OPEN: 'Open'
    },
    id: {
        DOWNLOAD_TITLE: 'Rasakan pengalaman yang lebih seru & beragam!',
        DOWNLOAD_DESCRIPTION: 'Gunakan aplikasi YoRipe di iOS atau Android',
        DOWNLOAD_APPSTORE: 'Unduh di Appstore',
        DOWNLOAD_PLAYSTORE: 'Unduh di Playstore',
        DOWNLOAD_BANNER: 'Unduh aplikasi untuk mengikuti tantangan seru!',
        YELLOW_TITLE: 'Kenalan sama koki rumahan, kreator & brand yang seminat denganmu!',
        YELLOW_PT1: 'Temukan lebih dari 10 ribu resep koki rumahan',
        YELLOW_PT2: 'Gabung grup untuk berbagi & belajar bareng',
        YELLOW_PT3: 'Ikuti tantangan & acara, menangkan hadiah',
        MOBILE_TITLE: 'Unduh YoRipe Secara Gratis!',
        MOBLIE_DESCRIPTION: 'Saat anda bergabung dengan kami, anda akan mendapatkan akses:',
        MOBILE_PT1: 'Mengikuti Tantangan Seru dan memenangkan hadiah',
        MOBILE_PT2: 'Bergabung dengan komunitas koki kami yang memiliki hobi yang sama',
        MOBILE_PT3: 'Membagikan masakan anda kepada komunitas',
        MOBILE_BUTTON: 'Unduh YoRipe',
        DOWNLOAD_APP: 'Unduh Aplikasi',
        SMART_BANNER: 'YoRipe lebih kece di app, lho!',
        GET_ON_PLAY: 'DAPATKAN – Di Google Play',
        GET_ON_APPSTORE: 'DAPATKAN – Di App Store',
        SEE_IN_APP: 'Lihat halaman ini di...',
        OPEN: 'Buka'
    }
};

export default download;
