const recipes = {
    en: {
        RECIPES_CATEGORY: 'CATEGORIES',
        RECIPES_FILTER_NEW: 'New',
        RECIPES_FILTER_LOCAL: 'Local',
        RECIPES_FILTER_ONE_POT: 'One Pot',
        RECIPES_FILTER_BAKING: 'Baking',
        RECIPES_FILTER_WESTERN: 'Western',
        RECIPES_FILTER_CHINESE: 'Chinese',
        RECIPES_FILTER_LOW_CARB: 'Low Carb',
        RECIPES_FILTER_BREAKFAST: 'Breakfast',
        RECIPES_FILTER_DESSERT: 'Dessert',
        RECIPES_FILTER_PASTA: 'Pasta/Noodles',
        RECIPES_FILTER_DINNER: 'Dinner',
        RECIPES_FILTER_VEGETARIAN: 'Vegetarian',
        RECIPES_FILTER_JAPANESE: 'Japanese',
        RECIPES_FILTER_SOUP: 'Soup',
        RECIPES_FILTER_BABY: 'Baby & Me',
        NEW_FILTER_TITLE: 'YoRipe’s New Recipes',
        NEW_FILTER_DESCRIPTION: 'Watch out for YoRipe’s newest homemade recipes!',
        LOCAL_FILTER_TITLE: 'YoRipe’s Local Recipes',
        LOCAL_FILTER_DESCRIPTION: 'Cook up Asian cuisines with YoRipe’s local recipes!',
        ONE_POT_FILTER_TITLE: 'YoRipe’s One Pot Recipes',
        ONE_POT_FILTER_DESCRIPTION: 'Make a flavorful one pot dish with YoRipe’s one pot recipes!',
        BAKING_FILTER_TITLE: 'YoRipe’s Baking Recipes',
        BAKING_FILTER_DESCRIPTION: 'Surprise friends and family with with YoRipe’s baking recipes!',
        WESTERN_FILTER_TITLE: 'YoRipe’s Western Recipes',
        WESTERN_FILTER_DESCRIPTION: 'Find your favorite Western dishes with homemade recipes from YoRipe!',
        CHINESE_FILTER_TITLE: 'YoRipe’s Chinese Recipes',
        CHINESE_FILTER_DESCRIPTION: 'Find your favorite Chinese dishes - stews, noodles, meats, rice - on YoRipe!',
        LOW_CARB_FILTER_TITLE: 'YoRipe’s Low Carb Recipes',
        LOW_CARB_FILTER_DESCRIPTION: 'Feeling fit? Try out leaner dishes with YoRipe’s low carb recipes!',
        BREAKFAST_FILTER_TITLE: 'YoRipe’s Breakfast Recipes',
        BREAKFAST_FILTER_DESCRIPTION: 'Get some morning inspiration with YoRipe’s breakfast recipes!',
        DESSERT_FILTER_TITLE: 'YoRipe’s Dessert Recipes',
        DESSERT_FILTER_DESCRIPTION: 'Satisfy your late night cravings with YoRipe’s dessert recipes!',
        PASTA_FILTER_TITLE: 'YoRipe’s Pasta/Noodle Recipes',
        PASTA_FILTER_DESCRIPTION: 'Find your favorite pasta & noodle dishes on YoRipe!',
        DINNER_FILTER_TITLE: 'YoRipe’s Dinner Recipes',
        DINNER_FILTER_DESCRIPTION: 'Get inspiration for your next dinner or party with YoRipe’s dinner recipes!',
        VEGETARIAN_FILTER_TITLE: 'YoRipe’s Vegetarian Recipes',
        VEGETARIAN_FILTER_DESCRIPTION: 'Find your next vegetarian delight with YoRipe’s vegetarian recipes!',
        JAPANESE_FILTER_TITLE: 'YoRipe’s Japanese Recipes',
        JAPANESE_FILTER_DESCRIPTION: 'Ramen? Oyako-don? Find your favorite Japanese recipes & ideas on YoRipe!',
        SOUP_FILTER_TITLE: 'YoRipe’s Soup Recipes',
        SOUP_FILTER_DESCRIPTION: 'Find your broths, stocks, or soups to pair with your meal with Yoripe’s soup recipes!',
        BABY_FILTER_TITLE: 'YoRipe’s Baby & Me Recipes',
        BABY_FILTER_DESCRIPTION: 'Nutritious, original and tasty meals for your kids! Check out our recipes at YoRipe!'
    },
    id: {
        RECIPES_CATEGORY: 'KATEGORI',
        RECIPES_FILTER_NEW: 'Baru',
        RECIPES_FILTER_LOCAL: 'Lokal',
        RECIPES_FILTER_ONE_POT: 'One Pot',
        RECIPES_FILTER_BAKING: 'Memanggang',
        RECIPES_FILTER_WESTERN: 'Masakan Barat',
        RECIPES_FILTER_CHINESE: 'Masakan China',
        RECIPES_FILTER_LOW_CARB: 'Rendah Karbohidrat',
        RECIPES_FILTER_BREAKFAST: 'Sarapan',
        RECIPES_FILTER_DESSERT: 'Hidangan Penutup',
        RECIPES_FILTER_PASTA: 'Pasta/Mie',
        RECIPES_FILTER_DINNER: 'Makan Malam',
        RECIPES_FILTER_VEGETARIAN: 'Vegetarian',
        RECIPES_FILTER_JAPANESE: 'Masakan Jepang',
        RECIPES_FILTER_SOUP: 'Sup',
        RECIPES_FILTER_BABY: 'Bayi & Saya',
        NEW_FILTER_TITLE: 'Resep Baru YoRipe',
        NEW_FILTER_DESCRIPTION: 'Nantikan resep buatan YoRipe yang terbaru!',
        LOCAL_FILTER_TITLE: 'Resep Lokal YoRipe',
        LOCAL_FILTER_DESCRIPTION: 'Masak masakan Asia dengan resep lokal YoRipe!',
        ONE_POT_FILTER_TITLE: 'Resep One Pot YoRipe',
        ONE_POT_FILTER_DESCRIPTION: 'Buat hidangan one pot yang beraroma dengan resep one pot YoRipe!',
        BAKING_FILTER_TITLE: 'Resep Memanggang YoRipe',
        BAKING_FILTER_DESCRIPTION: 'Beri kejutan kepada teman dan keluarga dengan resep memanggang YoRipe!',
        WESTERN_FILTER_TITLE: 'Resep Masakan Barat YoRipe',
        WESTERN_FILTER_DESCRIPTION: 'Temukan hidangan Barat favorit anda dengan resep buatan dari YoRipe!',
        CHINESE_FILTER_TITLE: 'Resep Masakan China YoRipe',
        CHINESE_FILTER_DESCRIPTION: 'Temukan hidangan China favorit anda - semur, mie, daging, nasi - di YoRipe!',
        LOW_CARB_FILTER_TITLE: 'Resep Rendah Karbohidrat YoRipe',
        LOW_CARB_FILTER_DESCRIPTION: 'Merasa sehat? Cobalah hidangan yang tidak berlemak dengan resep rendah karbohidrat YoRipe!',
        BREAKFAST_FILTER_TITLE: 'Resep Sarapan YoRipe',
        BREAKFAST_FILTER_DESCRIPTION: 'Dapatkan inspirasi pagi dengan resep sarapan YoRipe!',
        DESSERT_FILTER_TITLE: 'Resep Hidangan Penutup YoRipe',
        DESSERT_FILTER_DESCRIPTION: 'Penuhi keinginan makan malam anda dengan resep hidangan penutup YoRipe!',
        PASTA_FILTER_TITLE: 'Resep Pasta/Mie YoRipe',
        PASTA_FILTER_DESCRIPTION: 'Temukan pasta dan mie favorit anda di YoRipe!',
        DINNER_FILTER_TITLE: 'Resep Makan Malam YoRipe',
        DINNER_FILTER_DESCRIPTION: 'Dapatkan inspirasi untuk makan malam atau pesta anda dengan resep makan malam YoRipe!',
        VEGETARIAN_FILTER_TITLE: 'Resep Vegetarian YoRipe',
        VEGETARIAN_FILTER_DESCRIPTION: 'Temukan vegetarian kesukaan kamu selanjutnya dengan resep vegetarian YoRipe!',
        JAPANESE_FILTER_TITLE: 'Resep Masakan Jepang YoRipe',
        JAPANESE_FILTER_DESCRIPTION: 'Ramen? Oyako-don? Temukan resep dan ide makanan Jepang favorit anda di YoRipe!',
        SOUP_FILTER_TITLE: 'Resep Sup YoRipe',
        SOUP_FILTER_DESCRIPTION: 'Temukan berbagai macam kaldu untuk anda padukan dengan makanan anda dengan resep sup YoRipe!',
        BABY_FILTER_TITLE: 'Resep Bayi & Saya YoRipe',
        BABY_FILTER_DESCRIPTION: 'Makanan bergizi, orisinil, dan lezat untuk anak anda! Lihat resep kami di YoRipe!'
    }
};

export default recipes;
