const collections = {
    en: {
        COLLECTIONS_TITLE: 'Top Collections',
        COLLECTION_DESCRIPTION: 'Let\'s discover some amazing homemade recipes that are perfect to cook!',
        COPY_COLLECTION_LINK: 'Link copied to clipboard!',
        COLLECTION_EMPTY: 'No recipes in the top collection yet.\nWe\'re constantly adding delicious recipes for you to discover!'
    },
    id: {
        COLLECTIONS_TITLE: 'Koleksi Utama',
        COLLECTION_DESCRIPTION: 'Ayo temukan berbagai resep masakan rumahan yang cocok dimasak untukmu!',
        COPY_COLLECTION_LINK: 'Tautan disalin ke clipboard!',
        COLLECTION_EMPTY: 'Resepnya belum ada nih.\nKami sedang menambahkan resep yang enak untukmu!'
    }
};

export default collections;
