const challenges = {
    en: {
        CHALLENGES_TITLE: 'Challenges',
        CHALLENGES_DESCRIPTION: 'Want to have fun with like-minded cooks & win amazing prizes? Join YoRipe’s cooking challenges!',
        CHALLENGE_EMPTY: 'No Submissions yet! Be the first to share your cooking!',
        CHALLENGES_EMPTY1: 'No Submissions yet!',
        CHALLENGES_EMPTY2: 'be the first to share your cooking!',
        CHALLENGES_CHILLI: 'Only available in YoRipe App.',
        CHALLENGES_CHILLI_DESCRIPTION: 'Try something new, feel the joy of learning & win prizes by participating in cooking challenges.',
        INDIVIDUAL_BANNER: 'Download the app to participate challenge!',
        INDIVIDUAL_TITLE: 'Submissions',
        INDIVIDUAL_HEADER: 'Views',
        CHALLENGE_FILTER_ALL: 'ALL',
        ALL_FILTER_TITLE: 'Ongoing, trending and upcoming Challenges by YoRipe and its partners',
        ALL_FILTER_DESCRIPTION: 'Join any Challenge to express your creativity, compete friendly with fellow cooking and baking enthusiasts and win prizes.',
        CHALLENGE_FILTER_SGHOME: 'SG HOME CHEF IDOL',
        CHALLENGE_FILTER_INDOHOME: 'INDO HOME CHEF IDOL',
        CHALLENGE_FILTER_HEALTHIER: 'HEALTHIER GENERATION',
        HEALTHIER_FILTER_TITLE: 'Healthier Generation Challenges',
        HEALTHIER_FILTER_DESCRIPTION: 'Join any Challenge to express your creativity, compete friendly with fellow cooking and baking enthusiasts and win prizes.',
        SGHOME_FILTER_TITLE: 'Calling all the home chef and foodies to join YoRipe Singapore’s 2nd Home Chef Idol with Chef Ming Tan and Divian Nair!',
        SGHOME_FILTER_DESCRIPTION: 'Share your recipes to inspire others WIN PRIZES. We have 6 categories: #CreativeFlavours, #FamilyandKidsMeals, #SomethingNewWithDelMonte, #FestiveDrinkDesserts, #HuijiHealthyChristmas, #MeatLovers. Top 6 Finalists will get special chance to learn from Chef Ming Tan IN-PERSON at the finals!',
        SGHOME_FILTER_TITLE_INDO: 'The inaugural online recipe competition for Indonesia home chefs is happening now',
        CHALLENGE_FILTER_INGREDIENT: 'INGREDIENT',
        INGREDIENT_FILTER_TITLE: 'Challenge themed by ingredient',
        INGREDIENT_FILTER_DESCRIPTION: 'Chicken, rice, sauce, coconut milk and many more. Find new ways to cook your favourite ingredient! ',
        CHALLENGE_FILTER_CREATIVE: 'CREATIVE',
        CREATIVE_FILTER_TITLE: 'We can bring so much creativity to our cooking & baking, these Challenges are meant to push your creativity boudary',
        CREATIVE_FILTER_DESCRIPTION: 'Browse through all the creative challenges to get new inspiration, your next new creation may come from here!',
        CHALLENGE_FILTER_UPCOMING: 'UPCOMING',
        UPCOMING_FILTER_TITLE: "Don't miss out what new Challenges are on the way",
        UPCOMING_FILTER_DESCRIPTION: 'Be the first to check out details of upcoming challenges so you have more ample time to plan your submission. ',
        CHALLENGE_FILTER_DAILY: 'DAILY',
        DAILY_FILTER_TITLE: 'Something fun for you to join everyday and stay engaged with our warm community',
        DAILY_FILTER_DESCRIPTION: 'Share your all time favorites, onepot meal, clean out your fridge post and how you found life savior ingredients in your freezer moments through these challenges!',
        CHALLENGE_FILTER_BAKING: 'BAKING',
        BAKING_FILTER_TITLE: "For all baking enthusiastes, you'll definitely find something that catch your eye and send you back to your baking paradise",
        BAKING_FILTER_DESCRIPTION: 'From bread to cakes and savory baking goodness, share freshest out of your oven and inspire more amazing aromas in kitchens',
        CHALLENGE_USE_NOW: 'Use now'
    },
    id: {
        CHALLENGES_TITLE: 'Tantangan Seru',
        CHALLENGES_DESCRIPTION: 'Ingin bersenang-senang dengan koki rumahan yang sepemikiran dan menangkan hadiah yang menarik? Bergabunglah dengan tantangan memasak seru YoRipe!',
        CHALLENGE_EMPTY: 'Belum ada yang mengunggah! Jadilah orang pertama yang membagikan resep masakan anda!',
        CHALLENGES_EMPTY1: 'Belum ada yang mengunggah!',
        CHALLENGES_EMPTY2: 'Jadilah orang pertama yang membagikan resep masakan anda!',
        CHALLENGES_CHILLI: 'Hanya tersedia pada aplikasi YoRipe.',
        CHALLENGES_CHILLI_DESCRIPTION: 'Cobalah sesuatu yang baru, rasakan kegembiraan dari mempelajarinya dan menangkan hadiah dengan berpartisipasi pada tantangan memasak seru.',
        INDIVIDUAL_BANNER: 'Unduh aplikasi untuk mengikuti tantangan seru!',
        INDIVIDUAL_TITLE: 'Unggahan',
        INDIVIDUAL_HEADER: 'Tayangan',
        CHALLENGE_FILTER_ALL: 'SEMUA',
        ALL_FILTER_TITLE: 'Tantangan yang sedang berlangsung, trending, dan yang akan datang oleh YoRipe beserta mitranya',
        ALL_FILTER_DESCRIPTION: 'Bergabunglah dengan tantangan apa pun untuk mengekspresikan kreativitas Anda, bersaing secara sehat dengan sesama penggemar memasak dan membuat kue, serta menangkan hadiahnya',
        CHALLENGE_FILTER_SGHOME: 'SG HOME CHEF IDOL',
        CHALLENGE_FILTER_INDOHOME: 'INDO HOME CHEF IDOL',
        CHALLENGE_FILTER_HEALTHIER: 'HEALTHIER GENERATION',
        HEALTHIER_FILTER_TITLE: 'Healthier Generation Challenges',
        HEALTHIER_FILTER_DESCRIPTION: 'Join any Challenge to express your creativity, compete friendly with fellow cooking and baking enthusiasts and win prizes.',
        SGHOME_FILTER_TITLE_INDO: 'Kompetisi resep online perdana untuk koki rumahan Indonesia yang sedang berlangsung sekarang',
        CHALLENGE_FILTER_INGREDIENT: 'BAHAN-BAHAN',
        INGREDIENT_FILTER_TITLE: 'Tantangan dengan tema bahan masakan',
        INGREDIENT_FILTER_DESCRIPTION: 'Ayam, nasi, saus, santan, dan masih banyak lagi. Temukan cara baru untuk memasak bahan favorit Anda!',
        CHALLENGE_FILTER_CREATIVE: 'KREATIF',
        CREATIVE_FILTER_TITLE: 'Kami dapat membawa banyak kreativitas untuk masakan dan panggangan kami. Tantangan ini dimaksudkan untuk mendorong batasan kreativitas Anda',
        CREATIVE_FILTER_DESCRIPTION: 'Jelajahi semua tantangan kreatif untuk mendapatkan inspirasi baru, kreasi baru Anda berikutnya mungkin berasal dari sini!',
        CHALLENGE_FILTER_UPCOMING: 'MENDATANG',
        UPCOMING_FILTER_TITLE: 'Jangan lewatkan Tantangan baru apa yang akan segera datang',
        UPCOMING_FILTER_DESCRIPTION: 'Jadilah orang pertama yang memeriksa detail dari tantangan yang akan datang sehingga Anda memiliki lebih banyak waktu untuk merencanakan unggahan Anda',
        CHALLENGE_FILTER_DAILY: 'HARIAN',
        DAILY_FILTER_TITLE: 'Sesuatu yang menyenangkan bagi Anda untuk mengikuti tantangan setiap hari dan tetap terlibat pada komunitas kami',
        DAILY_FILTER_DESCRIPTION: 'Bagikan makanan favorit Anda sepanjang waktu, makanan onepot, bersihkan kulkas Anda dan bagaimana Anda menemukan bahan-bahan penyelamat masakan pada freezer melalui tantangan ini!',
        CHALLENGE_FILTER_BAKING: 'MEMANGGANG',
        BAKING_FILTER_TITLE: 'Untuk seluruh penggemar memanggang, Anda pasti akan menemukan sesuatu yang menarik perhatian dan membawa Anda kembali ke dunia panggangan',
        BAKING_FILTER_DESCRIPTION: 'Dari roti hingga kue dan panggangan yang gurih, bagikan makanan yang masih panas dari oven Anda dan sebarkan aroma yang menakjubkan di dapur',
        CHALLENGE_USE_NOW: 'Gunakan sekarang'
    }
};

export default challenges;
