const stories = {
    en: {
        STORIES_TITLE: 'Stories',
        STORIES_DESCRIPTION: 'Check out what’s cooking! Download the YoRipe Mobile App Now',
        STORIES_CHALLENGE_TITLE: 'Trending Challenge',
        STORIES_CHALLENGES_LEARN: 'Learn More',
        STORIES_CHALLENGES_MORE: 'SEE MORE CHALLENGES',
        STORIES_RECIPES_TITLE: 'New Recipes',
        STORIES_RECIPES_MORE: 'View More',
        INDIVIDUAL_REPLY: 'Reply',
        GENERAL_WRITE_COMMENTS: 'Write your comments...',
        INDIVIDUAL_REPORT: 'Report',
        INDIVIDUAL_SEE_RECIPE: 'See Recipe',
        INDIVIDUAL_GO_RECIPE: 'Go to Recipe',

        // Feed header
        FEED_MADE_PLAIN: 'made',
        FEED_WAS_LIVE: 'was live',
        FEED_ASKED_QUESTION: 'asked a question',
        FEED_SHARED: 'shared',
        FEED_REVIEW_MADE_RECIPE_1: 'made {publisher}\'s',
        FEED_REVIEW_MADE_RECIPE_2: 'recipe'
    },
    id: {
        STORIES_TITLE: 'Cerita',
        STORIES_DESCRIPTION: 'Lihat apa yang ingin dimasak! Unduh Aplikasi YoRipe di Telepon Genggam anda Sekarang!',
        STORIES_CHALLENGE_TITLE: 'Tantangan yang sedang Berlangsung',
        STORIES_CHALLENGES_LEARN: 'Pelajari Lebih Lanjut',
        STORIES_CHALLENGES_MORE: 'LIHAT LEBIH BANYAK TANTANGAN SERU',
        STORIES_RECIPES_TITLE: 'Resep Baru',
        STORIES_RECIPES_MORE: 'Lihat lebih banyak',
        INDIVIDUAL_REPLY: 'Balas',
        GENERAL_WRITE_COMMENTS: 'Tulis komentar Anda...',
        INDIVIDUAL_REPORT: 'Laporkan',
        INDIVIDUAL_SEE_RECIPE: 'Lihat Resep',
        INDIVIDUAL_GO_RECIPE: 'Pergi ke Resep',

        // Feed header
        FEED_MADE_PLAIN: 'membuat',
        FEED_WAS_LIVE: 'membuat siaran',
        FEED_ASKED_QUESTION: 'bertanya tentang',
        FEED_SHARED: 'membagikan',
        FEED_REVIEW_MADE_RECIPE_1: 'membuat Resep {publisher}',
        FEED_REVIEW_MADE_RECIPE_2: ''
    }
};

export default stories;
