import { Eloquent } from 'config/constants';

/**
 * Register user with email and password
 * @param {Object} data
 * @returns {Promise}
 */
export function register(data) {
    return Eloquent.Auth.register(data);
}

/**
 * Login user with email and password
 * @param {String} email
 * @param {String} password
 * @returns {Promise}
 */
export function login(email, password) {
    return Eloquent.Auth.login(email, password);
}

/**
 * Check if email is registered in the system
 * @param {String} email
 * @returns {Promise}
 */
export function checkEmail(email) {
    return Eloquent.Auth.checkEmail(email);
}

/**
 * Logout user
 * @returns {Promise}
 */
export function logout() {
    return Eloquent.Auth.logout();
}

/**
 * Login user with social account
 * @param {String} token - provided by Facebook/Google
 * @param {String} provider - only "facebook/google" supported
 * @returns {Promise}
 */
export function socialLogin(token, provider) {
    return Eloquent.Auth.socialLogin(provider, token);
}

/**
 * Login user with apple
 * @param {String} appleId
 * @param {String} email
 * @returns {Promise}
 */
export function loginWithApple(appleId, email) {
    return Eloquent.Auth.loginWithApple(appleId, email);
}

/**
 * Send Reset Password Code
 * @param {String} email
 * @param {String} link
 * @returns {Promise}
 */
export function sendResetPasswordCode(email, link) {
    return Eloquent.Auth.sendResetPasswordCode(email, link);
}

/**
 * Send Reset Password Code
 * @param {String} token
 * @param {String} password
 * @returns {Promise}
 */
export function resetPassword(token, password) {
    return Eloquent.Auth.resetPassword(token, password);
}

/**
 * Request OTP
 * @param {String} phone
 * @param {String} userID
 * @param {String} captchaToken
 * @returns {Promise}
 */
export function requestOTP(phone, userID, captchaToken) {
    return Eloquent.User.sendOTPToUsers({
        user_id: userID,
        mobile: phone,
        captchaToken
    });
}

/**
 * Verify OTP
 * @param {Object} data
 * @returns {Promise}
 */
export function verifyOTP(data) {
    return Eloquent.Auth.verifyOTP(data);
}

/**
 * Verify OTP
 * @param {Object} data
 * @returns {Promise}
 */
export function sendOTP(data) {
    return Eloquent.Auth.sendOTPToUsers(data);
}

/**
 * Get one analytics
 * @param {String} id
 * @returns {Promise}
 */
export function getOneAnalytics(id) {
    return Eloquent.PageView.getOne(id);
}

/**
 * Get one analytics
 * @param {String} id
 * @returns {Promise}
 */
export function updateOneAnalytics(id) {
    return Eloquent.PageView.updateOne(id);
}

/**
 * Create analytics
 * @param {String} page
 * @param {Number} views
 * @returns {Promise}
 */
export function createAnalytics(page, views) {
    return Eloquent.PageView.create({
        page: page,
        views: views
    });
}
