const recipe = {
    en: {
        RECIPE_TIME: 'mins',
        RECIPE_PREP: 'prep',
        RECIPE_COOK: 'cook',
        RECIPE_EASY: 'Easy',
        RECIPE_MEDIUM: 'Medium',
        RECIPE_HARD: 'Hard',
        RECIPE_CREATOR: 'Creator',
        RECIPE_DESCRIPTION: 'Description',
        RECIPE_INGREDIENTS: 'Ingredients',
        RECIPE_SERVINGS: 'Servings',
        RECIPE_NUTRITION: 'Nutritional Facts',
        RECIPE_CALORIES: 'Calories',
        RECIPE_CARBS: 'Carbs',
        RECIPE_FIBER: 'Fiber',
        RECIPE_PROTIEN: 'Protien',
        RECIPE_SATURATED: 'Saturated Fat',
        RECIPE_FAT: 'Total Fat',
        RECIPE_STEPS: 'Steps',
        RECIPE_STORIES: 'Cooking Stories',
        RECIPE_LIKES: 'Likes',
        RECIPE_SAVE_PROFILE: 'Save to your profile',
        RECIPE_SAVE: 'Save Recipe',
        RECIPE_SAVE_MESSAGE: 'Saved to your profile!',
        RECIPE_PROFILE: 'Go to your profile'
    },
    id: {
        RECIPE_TIME: 'menit',
        RECIPE_PREP: 'persiapan',
        RECIPE_COOK: 'pembuatan',
        RECIPE_EASY: 'Mudah',
        RECIPE_MEDIUM: 'Medium',
        RECIPE_HARD: 'Sulit',
        RECIPE_CREATOR: 'Kreator',
        RECIPE_DESCRIPTION: 'Deskripsi',
        RECIPE_INGREDIENTS: 'Bahan-bahan',
        RECIPE_SERVINGS: 'Porsi',
        RECIPE_NUTRITION: 'Fakta Nutrisi',
        RECIPE_CALORIES: 'Kalori',
        RECIPE_CARBS: 'Karbohidrat',
        RECIPE_FIBER: 'Serat',
        RECIPE_PROTIEN: 'Protien',
        RECIPE_SATURATED: 'Lemak Jenuh',
        RECIPE_FAT: 'Total Lemak',
        RECIPE_STEPS: 'Langkah-langkah',
        RECIPE_STORIES: 'Cerita Memasak',
        RECIPE_LIKES: 'Menyukai',
        RECIPE_SAVE_PROFILE: 'Simpan resep ini',
        RECIPE_SAVE: 'Simpan Resep',
        RECIPE_SAVE_MESSAGE: 'Berhasil disimpan ke profilmu!',
        RECIPE_PROFILE: 'Lihat di profil'
    }
};

export default recipe;
